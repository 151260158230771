import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import PropTypes from 'prop-types';

// individual tile used in info bar and review boxes, tile includes the box with the number and the label below the box
export const NumberBox = ({
  number,
  desc,
  isLoading,
  titlePosition = 'bottom',
  review = false,
  mb = false,
}) => {
  // determines the background color of the box that holds the number value
  const determineBackgroundColor = (value, type) => {
    let colors = ['#e35f8d', '#e38a76', '#e3b55f', '#a1c2a1', '#5fcfe3'];
    if (type === 'difficulty') {
      colors = colors.reverse();
    }
    if (value === 1) {
      return colors[0];
    } else if (value === 2) {
      return colors[1];
    } else if (value === 3) {
      return colors[0];
    } else if (value === 4) {
      return colors[3];
    } else {
      return colors[4];
    }
  };

  return (
    <div
      className={'d-flex flex-column text-center align-items-center' + (mb ? ' mb-2' : '')}
      style={{ width: '9%' }}
    >
      <div className="d-flex flex-column align-items-center">
        {/* puts the title at the top */}
        {titlePosition === 'top' ? (
          <span className="placeholder-glow mb-1">
            <span
              className={isLoading ? 'placeholder' : 'fw-bold d-block'}
              style={{ lineHeight: '18px' }}
            >
              {desc}
            </span>
          </span>
        ) : null}
        {/* the number box */}
        <div
          className="d-flex justify-content-center align-items-center placeholder-glow"
          style={{
            border: ' 2px solid black',
            boxShadow: '2px 2px #000000',
            backgroundColor: review
              ? determineBackgroundColor(number, desc.toLowerCase())
              : '#e35f8d',
            borderRadius: '6px',
            height: '80px',
            minWidth: '85px',
          }}
        >
          <span className={isLoading ? 'placeholder placeholder-lg' : 'fs-2 fw-bold'}>
            {number}
          </span>
        </div>
        {/* puts the title at the bottom */}
        {titlePosition === 'bottom' ? (
          <span className="placeholder-glow mt-1">
            <span
              className={isLoading ? 'placeholder' : 'fw-bold d-block'}
              style={{ lineHeight: '18px' }}
            >
              {desc}
            </span>
          </span>
        ) : null}
      </div>
    </div>
  );
};

NumberBox.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  desc: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  titlePosition: PropTypes.oneOf(['top', 'bottom']),
  review: PropTypes.bool,
  mb: PropTypes.bool,
};
