import React from 'react';
import 'App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import PropTypes from 'prop-types';
import 'bootstrap-icons/font/bootstrap-icons.css';

// status bar, used for announcements, bugs, future plans
export const StatusBar = ({ title, body, headerColor = 'pink' }) => {
  // determines the color of the header, output changes with the props provided by parent, default color is pink
  const determineHeaderColor = () => {
    if (headerColor === 'pink') {
      return '#e35f8d';
    } else if (headerColor === 'blue') {
      return '#5fcfe3';
    } else if (headerColor === 'yellow') {
      return '#e3b55f';
    }
  };

  // desktop version
  return (
    <div
      className="d-flex flex-column align-self-center"
      style={{
        border: '3px solid black',
        boxShadow: '4px 4px #000000',
        borderRadius: '10px',
      }}
    >
      {/* the title bar */}
      <div
        style={{
          backgroundColor: determineHeaderColor(),
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          display: 'block',
          borderBottom: '3px solid black',
          color: 'white',
        }}
        className="d-flex px-3 align-items-center py-1"
      >
        <>
          <i className="bi bi-info-circle me-1"></i>
          {' ' + title}
        </>
      </div>
      {/* the body part */}
      <div className="d-flex px-3 align-items-center py-2">{body}</div>
    </div>
  );
};

StatusBar.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  headerColor: PropTypes.oneOf(['pink', 'blue', 'yellow']),
};
