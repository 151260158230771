import React from 'react';
import 'App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useMediaQuery } from 'react-responsive';
import { Logo } from 'components/Elements';
import { ContentLayout } from 'components/Layout';

// about page
export const About = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <ContentLayout
      name="Course Rater About Page"
      description="What Course Rater is"
      title="About"
      type="About"
    >
      <div className="d-flex flex-column align-items-center">
        <div
          className={
            'd-flex flex-column align-self-center align-items-center ' +
            (isTabletOrMobile ? 'w-100 px-4' : ' w-50')
          }
        >
          <p
            className="fw-light text-black align-self-start"
            style={{ fontSize: '1.1em', lineHeight: '1.43em' }}
          >
            We want to provide a resource that assists students in selecting their schedules at
            Purdue. As fellow students who joined Purdue in Fall 2021, we experienced firsthand the
            challenges of navigating and picking an optimized schedule. It became evident that there
            was a significant demand for a comprehensive system that could provide enhanced course
            reviews and high-quality information. From countless students asking in the r/Purdue
            Reddit as well as in person, we thought that by creating this website, we could save
            students precious time and assist them in building more efficient and manageable
            schedules by offering in-depth reviews and valuable insights.
          </p>
          <p
            className="fw-light text-black align-self-start mt-1"
            style={{ fontSize: '1.1em', lineHeight: '1.43em' }}
          >
            This website was developed with the help of the Boiler Grades GitHub repository, which
            enabled us to retrieve the grade data from previous semesters. If you would like to see
            a different visual display of GPA data, we recommend visiting them at&nbsp;
            <a href="https://www.boilergrades.com/">boilergrades.com</a>. The grade data we use was
            obtained by Erik Duxstad, the creator of Boiler Grades, through a public records request
            to Purdue University. Data from Fall 2021 onwards was provided to Erik by Joey Diesen.
            As Erik states, “Correlation does not equal causation.” Therefore, while our platform
            serves as a useful tool, it should be complemented with advisor consultations and other
            steps before making significant schedule decisions.
          </p>
          <p
            className="fw-light text-black align-self-start mt-1"
            style={{ fontSize: '1.1em', lineHeight: '1.43em' }}
          >
            To create an account with us you have to be a Purdue student and sign in through your
            Purdue email.
          </p>
          <p
            className="fw-light text-black align-self-start mt-1"
            style={{ fontSize: '1.1em', lineHeight: '1.43em' }}
          >
            {' '}
            This project is not affiliated with Purdue University. The creators do not profit from
            this project in any way.
          </p>
          <p
            className="fw-light text-black align-self-start mt-1"
            style={{ fontSize: '1.1em', lineHeight: '1.43em' }}
          >
            {' '}
            For questions, bugs, etc., contact us at courseraters@gmail.com or on our{' '}
            <a href="https://discord.gg/ZabPE5NbmY" target="_blank" rel="noreferrer">
              discord server
            </a>
            .
          </p>
          <p
            className={'fw-light text-black align-self-start mt-2 ' + (isTabletOrMobile ? '' : '')}
            style={{ fontSize: '1.1em', lineHeight: '1.43em' }}
          >
            {' '}
            &copy; Leyton Bostre and Anthony Gentile
          </p>
          <div className="px-3 pb-4">
            <Logo />
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
