import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import './AutoComplete.scss';
import PropTypes from 'prop-types';

// component that can autocomplete a phrase that the user may be typing
export const AutoComplete = ({ size, options, placeholder, handleChange }) => {
  // signifies if user is using input
  const [isFocused, setIsFocused] = useState(false);

  // returns minimum height for the input
  const handleSize = () => {
    if (size === 'small') {
      return '1em';
    }
    return '2.7em';
  };

  // render menu, used for custom menu rendering
  const renderMenu = useCallback((results, menuProps, props) => {
    return (
      <Menu {...menuProps} className="typahead-menu">
        {results.map((result, idx) => (
          <MenuItem key={result.toString()} option={result} position={idx} className="fs-5">
            {result.paginationOption ? (
              <Highlighter search={props.text}>Load more classes...</Highlighter>
            ) : (
              <Highlighter search={props.text}>{result.toString()}</Highlighter>
            )}
          </MenuItem>
        ))}
      </Menu>
    );
  }, []);

  return (
    <div className="w-100">
      <Form className="w-100">
        <div className="d-flex flex-column align-items-center">
          {/*Typeahead component*/}
          <Typeahead
            id="course-typeahead"
            style={{ width: '100%', minWidth: '40%' }}
            onChange={(value) => {
              handleChange(value);
            }}
            options={options}
            placeholder={placeholder}
            minLength={2}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            inputProps={{
              style: {
                boxShadow: isFocused ? '0 0 0 3px #e35f8d' : '0 0 0px #000000',
                borderColor: 'black',
                borderWidth: '3px',
                borderRadius: '5px',
                minHeight: handleSize(),
                fontSize: '1.3em',
              },
            }}
            renderMenu={renderMenu}
          />
        </div>
      </Form>
    </div>
  );
};

// prop types
AutoComplete.propTypes = {
  size: PropTypes.oneOf(['small', 'other']),
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  text: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};
