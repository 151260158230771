import React, { useEffect, useReducer, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../styles/Class.scss';
import {
  getClassAverage,
  getClassDesc,
  getClassProfessors,
  getClassTerms,
  getProfessorAverage,
  getProfessorGrades,
  getProfessorsFromTerm,
} from 'data/CourseParser';
import { InfoBar } from 'features/courses/components/InfoBar';
import { Modal, BarGraph, Dropdown, Button } from 'components/Elements';
import { useParams } from 'react-router-dom';
import { SmallSearchBar } from 'features/courses/components/SmallSearchBar';
import { useMediaQuery } from 'react-responsive';
import { Head } from 'components/Head';
import { CreateReview } from 'features/reviews/components/CreateReview';
import { ReviewList } from 'features/reviews';
import { getReviews } from 'features/reviews/api/getReviews';
import { useCredits } from 'features/courses/api/getCredits';

// reducer function used for altering state
function reducer(state, action) {
  switch (action.type) {
    // changing a class
    case 'change_class': {
      const subject = action.newCourseName.split(' ')[0];
      const courseNum = action.newCourseName.split(' ')[1];
      const terms = getClassTerms(subject, courseNum);
      const profs = getProfessorsFromTerm(terms[0], subject, courseNum);
      return {
        subject: subject,
        courseNum: courseNum,
        terms: terms,
        term: terms[0],
        courseDesc: getClassDesc(terms[0], subject, courseNum),
        profs: profs,
        professor: profs[0],
        grades: getProfessorGrades(terms[0], subject, courseNum, profs[0]),
        gpa: getProfessorAverage(terms[0], subject, courseNum, profs[0]),
        yGpa: getClassAverage(terms[0], subject, courseNum),
        credits: 0,
      };
    }
    // changing the term from the dropdown
    case 'change_term': {
      let newProfessors = getProfessorsFromTerm(action.newTerm, state.subject, state.courseNum);
      return {
        ...state,
        term: action.newTerm,
        profs: newProfessors,
        yGpa: getClassAverage(action.newTerm, state.subject, state.courseNum),
        professor: newProfessors[0],
        grades: getProfessorGrades(
          action.newTerm,
          state.subject,
          state.courseNum,
          newProfessors[0]
        ),
        gpa: getProfessorAverage(action.newTerm, state.subject, state.courseNum, newProfessors[0]),
      };
    }
    // changing the prof from the dropdown
    case 'change_prof': {
      return {
        ...state,
        professor: action.newProfessor,
        grades: getProfessorGrades(state.term, state.subject, state.courseNum, action.newProfessor),
        gpa: getProfessorAverage(state.term, state.subject, state.courseNum, action.newProfessor),
      };
    }
    // changes the credits
    case 'change_credits': {
      return {
        ...state,
        credits: action.newCredits,
      };
    }
    // changes the course description
    case 'change_course_description': {
      return {
        ...state,
        courseDesc: action.newCourseDescription,
      };
    }
    default:
      throw Error('Unknown action: ' + action.type);
  }
}

// class page, page that shows class averages, gpa's, and reviews
export const Class = () => {
  let { courseName } = useParams();
  courseName = courseName.replace('-', ' ').toUpperCase();
  const subject = courseName.split(' ')[0];
  const courseNum = courseName.split(' ')[1];
  const terms = getClassTerms(subject, courseNum);
  const profs = getProfessorsFromTerm(terms[0], subject, courseNum);
  const [state, dispatch] = useReducer(reducer, {
    subject: subject,
    courseNum: courseNum,
    terms: terms,
    term: terms[0],
    courseDesc: getClassDesc(terms[0], subject, courseNum),
    profs: profs,
    professor: profs[0],
    grades: getProfessorGrades(terms[0], subject, courseNum, profs[0]),
    gpa: getProfessorAverage(terms[0], subject, courseNum, profs[0]),
    yGpa: getClassAverage(terms[0], subject, courseNum),
    credits: 0,
  });

  // creates state for all necessary items for a class
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const [show, setShow] = useState(false);
  const [reviewCategory, setReviewCategory] = useState('Best');
  const [reviewProf, setReviewProf] = useState('All Professors');
  const [showSorterDropdown, setShowSorterDropdown] = useState(false);
  // uses credits query to fetch class credits
  const creditsQuery = useCredits({ className: courseName });

  // loads necessary class information as well as its reviews on page load
  // then sets respective states to its loaded information
  useEffect(() => {
    (async () => {
      getReviews({ courseName }).then((r) => {
        if (r && r[0].length > 1) setShowSorterDropdown(true);
      });
    })();

    dispatch({
      type: 'change_class',
      newCourseName: courseName,
    });
  }, [courseName]);

  // handles when user presses "Write a Review" button
  const styles = () => {
    let style = {};
    style['titleFontSize'] = '2.5em';
    style['descFontSize'] = 'fs-2';
    style['credFontSize'] = 'fs-4';
    style['infoBarDiv'] = 'mt-3 mb-5';
    style['dropdownDiv'] = 'd-flex flex-row w-75 align-self-center justify-content-between mb-3';
    style['graphDiv'] = 'd-flex flex-column align-items-center align-self-center w-75 h-100';
    style['gpaDiv'] = 'd-flex flex-row align-self-center w-75 justify-content-end';
    style['reviewButtonDiv'] = 'd-flex align-self-center justify-content-between w-75';
    if (isTabletOrMobile) {
      style['infoBarDiv'] = 'mb-3';
      style['dropdownDiv'] = 'd-flex flex-row align-self-center w-100 justify-content-between mb-3';
      style['graphDiv'] = 'd-flex flex-column align-items-center mb-3 h-50';
      style['gpaDiv'] = 'd-flex flex-row align-self-end w-75 justify-content-end';
      style['reviewButtonDiv'] = 'd-flex justify-content-between';
      if (isMobile || isPortrait) {
        style['titleFontSize'] = '2.25em';
        style['descFontSize'] = 'fs-3';
        style['credFontSize'] = 'fs-5';
        style['dropdownDiv'] = 'd-flex flex-row w-100 justify-content-between mb-3';
        style['graphDiv'] = 'd-flex flex-column align-items-center mb-3 h-50 me-4';
        if (isPortrait) {
          style['titleFontSize'] = '2.5em';
          style['descFontSize'] = 'fs-2';
          style['credFontSize'] = 'fs-4';
        }
      }
    }
    return style;
  };

  // subgraph is the part below the graph that displays prof gpa, class gpa, write a review button, and class sorter dropdown
  const subGraph = () => {
    if (isMobile || isPortrait) {
      return (
        <div className="container">
          <div className="row mb-2">
            <div className="col">
              <p className="fw-bold">Average GPA this term: {state.yGpa}</p>
            </div>
            <div className="col d-flex justify-content-end h-75">
              {/* "write a review" button */}
              <CreateReview courseName={courseName} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="fw-bold">Professor&apos;s Average GPA: {state.gpa}</p>
            </div>
            <div className="col d-flex justify-content-end">
              {/* modal that displays if the user already has a review */}
              <Modal
                show={show}
                handleClose={() => setShow(false)}
                title="You have already reviewed this class."
                body="If you would like to create a new review, please delete the review that you have already made."
              />
              {/* the class sorter dropdown */}
              {showSorterDropdown && (
                <Dropdown
                  list={['Best', 'Worst', 'Old', 'New']}
                  value={reviewCategory}
                  onChangeValue={(category) => setReviewCategory(category)}
                  showSortBy={true}
                  color="blue"
                />
              )}
            </div>
          </div>
        </div>
      );
    } else {
      // the version for desktop and landscape tablets
      return (
        <div className="d-flex flex-column">
          <div className={styles()['gpaDiv']}>
            <p className="fs-6 fw-bold me-3">Average GPA this term: {state.yGpa}</p>
            <p className="fs-6 fw-bold">Professor&apos;s Average GPA: {state.gpa}</p>
          </div>
          <div className={styles()['reviewButtonDiv']}>
            <CreateReview courseName={courseName} />
            <Modal
              show={show}
              handleClose={() => setShow(false)}
              title="You have already reviewed this class."
              body="If you would like to create a new review, please delete the review that you have already made."
            />
            {showSorterDropdown && (
              <div className="d-flex flex-row column-gap-2">
                <Dropdown
                  list={['Best', 'Worst', 'Old', 'New']}
                  value={reviewCategory}
                  onChangeValue={(category) => setReviewCategory(category)}
                  showSortBy={true}
                  color="blue"
                />
                <Dropdown
                  list={['All Professors', ...getClassProfessors(subject, courseNum)]}
                  value={reviewProf}
                  onChangeValue={(prof) => setReviewProf(prof)}
                  color="blue"
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Head
        title={subject + ' ' + courseNum + ' | Course Rater'}
        description={'Rate and Review ' + subject + ' ' + courseNum}
        name="Course Rater"
        type={'Class: ' + subject + ' ' + courseNum}
      />
      <div className="d-flex flex-column mx-3">
        <div className="align-self-end">
          {/* the search bar in the top right */}
          <SmallSearchBar />
        </div>
        {/* all the text at the top including course name, desc, and credits */}
        <div className="d-flex flex-column align-self-center text-center">
          <p className="fw-bolder text-black" style={{ fontSize: styles()['titleFontSize'] }}>
            {courseName}
          </p>
          <p className={'fw-bold ' + styles()['descFontSize']}>{state.courseDesc}</p>
          <p className={'fw-bold placeholder-glow ' + styles()['credFontSize']}>
            Credits:{' '}
            <span className={creditsQuery.isLoading ? 'placeholder' : ''}>
              {creditsQuery?.data}
            </span>
          </p>
        </div>
        {/* the info bar below the text */}
        <div className={'d-flex flex-column ' + styles()['infoBarDiv']}>
          <InfoBar courseName={courseName} subject={state.subject} courseNum={state.courseNum} />
        </div>
        {/* buttons that link to r/Purdue reddit and Purdue course catalog */}
        <div className={styles()['dropdownDiv']}>
          <div
            className={isMobile ? 'd-flex flex-column row-gap-2' : 'd-flex flex-row column-gap-2'}
          >
            <Button
              text="r/Purdue"
              variant="reddit"
              color="white"
              onClick={() =>
                window.open(
                  'https://www.reddit.com/r/purdue/search?q=' +
                    subject +
                    ' ' +
                    courseNum +
                    '&restrict_sr=on&sort=relevance&t=all',
                  '_blank'
                )
              }
            />
            <Button
              text="Catalog"
              variant="purdue"
              onClick={() =>
                window.open(
                  'https://selfservice.mypurdue.purdue.edu/prod/bzwsrch.p_catalog_detail?subject=' +
                    subject +
                    '&cnbr=' +
                    courseNum +
                    '&enhanced=Y',
                  '_blank'
                )
              }
            />
          </div>
          {/* dropdowns for term and professor */}
          <div
            className={
              isMobile
                ? 'd-flex flex-column row-gap-2 align-items-end'
                : 'd-flex flex-row column-gap-2'
            }
          >
            <Dropdown
              list={state.terms}
              value={state.term}
              onChangeValue={(term) => dispatch({ type: 'change_term', newTerm: term })}
            />
            <Dropdown
              list={state.profs}
              value={state.professor}
              onChangeValue={(prof) => dispatch({ type: 'change_prof', newProfessor: prof })}
            />
          </div>
        </div>
        {/* bar graph and information directly below graph */}
        <div className={styles()['graphDiv']}>
          <BarGraph graphData={state.grades} type="class" />
        </div>
        {subGraph()}
        {/* all the reviews  */}
        <ReviewList courseName={courseName} sortingCategory={reviewCategory} prof={reviewProf} />
      </div>
    </div>
  );
};
