const json = require('./data.json');
const scoresJson = require('./lowdata.json');

function getOccurrence(array, value) {
  let count = 0;
  array.forEach((v) => v === value && count++);
  return count;
}

// returns grades based on term -> subject -> course number -> professor
export function getProfessorGrades(term, subject, courseNum, profName) {
  let ref = [
    'A+',
    'A',
    'A-',
    'B+',
    'B',
    'B-',
    'C+',
    'C',
    'C-',
    'D+',
    'D',
    'D-',
    'F',
    'AU',
    'I',
    'N',
    'NS',
    'P',
    'PI',
    'S',
    'SI',
    'U',
    'W',
    'WF',
    'WU',
  ];
  let grades = {};
  let profs = [];
  let offset = 0;
  for (let i = 0; i < json[term][subject][courseNum].length; i++) {
    if (json[term][subject][courseNum][i].constructor === Object) {
      profs.push(Object.keys(json[term][subject][courseNum][i])[0]);
    } else {
      offset += 1;
    }
  }
  // if the professor has more than one section for that given class, average out their sections
  if (getOccurrence(profs, profName) > 1) {
    let total = 0;
    let gradeArr = new Array(ref.length).fill(0);
    let gradeLetterArray = {};
    let gradeNumberArray = {};

    // gets totals across all sections
    for (let i = 0; i < profs.length; i++) {
      let tempGrades = {};
      if (profs[i] === profName) {
        tempGrades = json[term][subject][courseNum][i + offset][profName];
        gradeLetterArray = Object.keys(tempGrades);
        gradeNumberArray = Object.values(tempGrades);
        if (total === 0) {
          for (let k = 0; k < gradeLetterArray.length; k++) {
            gradeArr[ref.indexOf(gradeLetterArray[k])] = parseFloat(gradeNumberArray[k]);
          }
        } else {
          for (let k = 0; k < gradeLetterArray.length; k++) {
            gradeArr[ref.indexOf(gradeLetterArray[k])] += parseFloat(gradeNumberArray[k]);
          }
        }
        total++;
      }
    }

    // calculates averages across professor's section totals
    for (let i = 0; i < gradeArr.length; i++) {
      if (gradeArr[i] !== 0) {
        grades[ref[i]] = Math.round((gradeArr[i] / total) * 100) / 100;
      } else {
        delete grades[ref[i]];
      }
    }
  } else {
    grades = json[term][subject][courseNum][profs.indexOf(profName) + offset][profName];
  }

  // sorts grades then returns them
  return sortGrades(grades);
}

// returns professors gpa from term -> subject -> course number -> professor
export function getProfessorAverage(term, subject, courseNum, profName) {
  // grades and their weight
  let gradePoints = {
    'A+': 4.0,
    A: 4.0,
    'A-': 3.7,
    'B+': 3.3,
    B: 3.0,
    'B-': 2.7,
    'C+': 2.3,
    C: 2.0,
    'C-': 1.7,
    'D+': 1.3,
    D: 1.0,
    'D-': 0.7,
    F: 0.0,
    E: 0.0,
  };
  let gradePercents = 0;
  let gradeTotal = 0;

  // get professors grades
  let grades = getProfessorGrades(term, subject, courseNum, profName);

  // find grade point total
  for (const grade in grades) {
    if (grade in gradePoints) {
      gradeTotal += gradePoints[grade] * parseFloat(grades[grade]);
      gradePercents += parseFloat(grades[grade]);
    }
  }

  // if the total is zero, then we have no gpa, otherwise, calculate and return gpa
  if (gradeTotal === 0 || gradePercents === 0) {
    return 'N/A';
  }
  return Math.round((gradeTotal / gradePercents) * 100) / 100;
}

// takes in grades, sorts them, then returns them
export function sortGrades(grades) {
  // order of the grades
  let ref = [
    'A+',
    'A',
    'A-',
    'B+',
    'B',
    'B-',
    'C+',
    'C',
    'C-',
    'D+',
    'D',
    'D-',
    'F',
    'AU',
    'I',
    'N',
    'NS',
    'P',
    'PI',
    'S',
    'SI',
    'U',
    'W',
    'WF',
    'WU',
  ];

  ref = ref.reverse();
  // create dictionary and adds to it if the letter grade exists in professor's grades
  let sortedGrades = {};
  for (let i = 0; i < ref.length; i++) {
    if (Object.keys(grades).includes(ref[i])) {
      sortedGrades[ref[i]] = grades[ref[i]];
    }
  }
  return sortedGrades;
}

// takes a grade and returns a color correlating to that grade
// green = good, yellow = ok, red = bad
const chooseBGColor = (grade) => {
  if (grade.includes('A')) {
    return '#50da11';
  } else if (grade.includes('B')) {
    return '#badb13';
  } else if (grade.includes('C')) {
    return '#e1a916';
  } else if (grade.includes('D')) {
    return '#db600e';
  } else if (grade.includes('F')) {
    return '#dc120d';
  } else {
    return '#000';
  }
};

// return a professors grades as an array of the Form [letter grades, grader percents, background colors]
// input is term -> subject -> course number -> professor
export function getProfessorGradesAsArray(term, subject, courseNum, profName) {
  let grades;
  let gradeLetters = [];
  let gradePercents = [];
  let bgColors = [];
  let profs = [];
  let offset = 0;

  // initializes profs list by adding each professor to an array for a given term, subject, and course number
  // also keeps track of the offset from the start of the course object to the professor
  for (let i = 0; i < json[term][subject][courseNum].length; i++) {
    if (json[term][subject][courseNum][i].constructor === Object) {
      profs.push(Object.keys(json[term][subject][courseNum][i])[0]);
    } else {
      offset += 1;
    }
  }
  // gets grades of professor as a dictionary and sorts them
  grades = json[term][subject][courseNum][profs.indexOf(profName) + offset][profName];
  grades = sortGrades(grades);

  // creates color and percentage array for each grade letter
  for (const gradeLetter in grades) {
    gradeLetters.push(gradeLetter);
    bgColors.push(chooseBGColor(gradeLetter));
    if (gradeLetter !== 'avg') {
      gradePercents.push(parseFloat(grades[gradeLetter]));
    } else {
      gradePercents.push(grades[gradeLetter]);
    }
  }

  return [gradeLetters, gradePercents, bgColors];
}

// returns the terms sorted from most to least recent
export function sortTerms(terms) {
  let dates = [];
  let sortedDates = [];
  let sortedTerms = [];

  // creates quantifiable sortable data by representing semesters by their starting day and as a Date data type
  for (let i = 0; i < terms.length; i++) {
    let season = terms[i].split(' ')[0];
    let year = terms[i].split(' ')[1];
    if (season === 'Fall') {
      dates.push(new Date(year + '-08-22'));
    } else if (season === 'Summer') {
      dates.push(new Date(year + '-05-15'));
    } else {
      dates.push(new Date(year + '-01-09'));
    }
  }

  // sorts dates from most to least recent
  sortedDates = dates.sort(function (a, b) {
    return b - a;
  });

  // converts dates back into semester names
  for (let j = 0; j < sortedDates.length; j++) {
    let year = sortedDates[j].toString().split(' ')[3];
    let month = sortedDates[j].toString().split(' ')[1];
    if (month === 'Aug') {
      sortedTerms.push('Fall ' + year);
    } else if (month === 'May') {
      sortedTerms.push('Summer ' + year);
    } else {
      sortedTerms.push('Spring ' + year);
    }
  }
  return sortedTerms;
}

// returns all the terms that the class was offered in
export function getClassTerms(subject, courseNum) {
  let terms;
  let validTerms;
  terms = Object.keys(json);
  validTerms = [];
  for (let i = 0; i < terms.length; i++) {
    if (subject in json[terms[i]]) {
      if (courseNum in json[terms[i]][subject]) {
        validTerms.push(terms[i]);
      }
    }
  }
  return sortTerms(validTerms);
}

// sorts last names by alphabetical order, input are two names that are "first last"
function lastNameSort(a, b) {
  return a.split(' ').pop()[0] > b.split(' ').pop()[0];
}

// returns all professors that a class has had
export function getClassProfessors(subject, courseNum) {
  let profs = [];
  let terms = [];
  let prof = '';
  terms = getClassTerms(subject, courseNum);
  for (let k = 0; k < terms.length; k++) {
    for (let i = 0; i < json[terms[k]][subject][courseNum].length; i++) {
      if (json[terms[k]][subject][courseNum][i].constructor === Object) {
        prof = Object.keys(json[terms[k]][subject][courseNum][i])[0];
        if (!profs.includes(prof)) {
          profs.push(prof);
        }
      }
    }
  }
  return profs.sort(lastNameSort);
}

// returns all professors that taught a class in a given term
export function getProfessorsFromTerm(term, subject, courseNum) {
  let profs = [];
  let prof;
  for (let i = 0; i < json[term][subject][courseNum].length; i++) {
    if (json[term][subject][courseNum][i].constructor === Object) {
      prof = Object.keys(json[term][subject][courseNum][i])[0];
      if (!profs.includes(prof)) {
        profs.push(prof);
      }
    }
  }
  return profs.sort(lastNameSort);
}

// find class average for a class for a given term, gpa across all sections
export function getClassAverage(term, subject, courseNum) {
  let gpaTotal = 0;
  let profs = [];
  for (let i = 0; i < json[term][subject][courseNum].length; i++) {
    if (
      json[term][subject][courseNum][i].constructor === Object &&
      !profs.includes(Object.keys(json[term][subject][courseNum][i])[0])
    ) {
      profs.push(Object.keys(json[term][subject][courseNum][i])[0]);
      gpaTotal += getProfessorAverage(
        term,
        subject,
        courseNum,
        Object.keys(json[term][subject][courseNum][i])[0]
      );
    }
  }
  if (!isNaN(gpaTotal) && gpaTotal !== 0) {
    return Math.round((gpaTotal / profs.length) * 100) / 100;
  }
  return 'N/A';
}

// returns description for a class (ECE 20001 -> Electrical Engineering Fundamentals I)
export function getClassDesc(term, subject, courseNum) {
  return json[term][subject][courseNum][0];
}

// return JSON (for testing purposes)
export function displayJson() {
  return json;
}

export function parseJson() {
  for (var i in json) {
    var key = i;
    var val = json[key];
    for (var j in val) {
      console.log(key + ' ' + j);
    }
  }
}

// returns a list of all classes across all semesters
export function getListOfClasses() {
  let course_set = new Set();
  for (const year in json) {
    for (const subject in json[year]) {
      for (const courseNum in json[year][subject]) {
        let courseName = subject + '' + courseNum;
        course_set.add(courseName);
      }
    }
  }
  return Array.from(course_set);
}

// gets all the terms inside the L20 data
export function getL20Terms() {
  let terms = Object.keys(scoresJson);
  terms.shift();
  terms = sortTerms(terms);
  terms.unshift('All Time');
  return terms;
}

// gets the data from the L20 data given a term
export function getL20(term) {
  let classes = [];
  let gpas = [];
  for (let i = 0; i < scoresJson[term].length; i++) {
    classes.push(scoresJson[term][i][0]);
    gpas.push(scoresJson[term][i][1]);
  }
  return [classes, gpas];
}

// returns true if class is valid
export function isClassValid(subject, courseNum) {
  let terms;
  let validTerms = [];
  terms = Object.keys(json);
  for (let i = 0; i < terms.length; i++) {
    if (subject in json[terms[i]]) {
      if (courseNum in json[terms[i]][subject]) {
        validTerms.push(terms[i]);
      }
    }
  }
  return validTerms.length;
}

// ??
export function findClassMatchingInput(input, maxResults = 0) {
  let resultCount = 0;
  const resultList = [];
  for (const key in json) {
    const val = json[key];
    for (const j in val) {
      const matchCase = key + ' ' + j;
      if (matchCase.substring(0, input.length) === input) {
        if (maxResults) {
          if (resultCount > maxResults) {
            return resultList;
          }
        }
        resultList.push(matchCase);
        resultCount++;
      }
    }
  }
  return resultList;
}
