import React, { useState } from 'react';
import { Button } from 'components/Elements/Button/Button';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { isIOS } from 'react-device-detect';
import LoginModal from 'features/auth/components/LoginModal';

// the login/logout button on the navbar
export default function LoginLogoutButton() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [show, setShow] = useState(false);

  // handles when a user chooses to log out
  const handleLogout = (instance) => {
    if (isIOS) {
      void instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    } else {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    }
  };

  // handles when to show the login modal
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // if the user is logged in, show the logout button
  if (isAuthenticated) {
    return (
      <>
        <Button text="Logout" color="pink" onClick={() => handleLogout(instance)} />
      </>
    );
  }

  // otherwise is the user is not logged in, show a login button that triggers the login modal
  return (
    <>
      <Button text="Login" color="white" onClick={handleShow} />
      {show && <LoginModal show={show} handleClose={() => handleClose()} />}
    </>
  );
}
