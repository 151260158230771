import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'components/Form/styles/RadioField.scss';
import { FieldWrapper } from 'components/Form/FieldWrapper';
import PropTypes from 'prop-types';

// custom radio field used for forms, allows users to pick between yes and no options
export const RadioField = ({ label, error, registration }) => {
  return (
    <FieldWrapper label={label} error={error}>
      <div className="d-flex mt-2">
        <div className="d-flex flex-column align-items-center me-2">
          <input className="radio-yes" {...registration} type="radio" value={true} />
          <p className="fw-bold">Yes</p>
        </div>
        <div className="d-flex flex-column align-items-center ms-2">
          <input className="radio-no" {...registration} type="radio" value={false} />
          <p className="fw-bold">No</p>
        </div>
      </div>
    </FieldWrapper>
  );
};

RadioField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  registration: PropTypes.object.isRequired,
};