import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { zodResolver } from '@hookform/resolvers/zod';

// Form component, used to create forms
export const Form = ({ onSubmit, children, className, options, id, schema }) => {
  const methods = useForm({ ...options, resolver: schema && zodResolver(schema) });
  return (
    <form className={clsx('my-2', className)} onSubmit={methods.handleSubmit(onSubmit)} id={id}>
      {children(methods)}
    </form>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  options: PropTypes.object,
  id: PropTypes.string,
  schema: PropTypes.object,
};
