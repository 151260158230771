import * as React from 'react';

import { Head } from '../Head';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// content layout, mainly used for pages with big title at top
// contains head for SEO
export const ContentLayout = ({ children, title, description, name, type }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <>
      <Head title={title} description={description} name={name} type={type} />
      <div className="w-100 h-100">
        <div className="p-3">
          <div className="d-flex justify-content-center align-items-center text-center">
            {/* header text */}
            <h1
              className="fw-bolder text-black"
              style={{ fontSize: isTabletOrMobile ? '2.8em' : '4em' }}
            >
              {title}
            </h1>
          </div>
        </div>
        {/* rest of page */}
        <div>{children}</div>
      </div>
    </>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
