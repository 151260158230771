import { QueryClient } from 'react-query';
// query config for react query
const queryConfig = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
  },
};
// the query client that is used for react-query
export const queryClient = new QueryClient({ defaultOptions: queryConfig });
