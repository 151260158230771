import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'features/reviews/styles/Voter.scss';
import { useIsAuthenticated } from '@azure/msal-react';
import LoginModal from 'features/auth/components/LoginModal';
import PropTypes from 'prop-types';

// voter component (upvote/downvote buttons), purpose is to allow users to upvote/downvote reviews
export const Voter = (props) => {
  const { up, down } = props;
  const [upVoted, setUpVoted] = useState(false);
  const [downVoted, setDownVoted] = useState(false);
  const [show, setShow] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  // on page load, set options to either upvoted or downvoted
  useEffect(() => {
    if (up) {
      setUpVoted(true);
    } else if (down) {
      setDownVoted(true);
    }
  }, [down, up]);

  // handles when user presses either upvote or downvote (only visual changes occur)
  const handleVote = (vote) => {
    if (isAuthenticated) {
      if (vote === 'up') {
        if (upVoted) {
          props.handleVote('retract');
        } else {
          props.handleVote(vote);
        }
        setUpVoted(!upVoted);
        setDownVoted(false);
      } else if (vote === 'down') {
        if (downVoted) {
          props.handleVote('retract');
        } else {
          props.handleVote(vote);
        }
        setUpVoted(false);
        setDownVoted(!downVoted);
      }
    } else {
      setShow(true);
    }
  };

  // handles when user presses close on the login modal
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="d-flex w-25">
      {/* up and down array icon buttons */}
      <button className="btn me-2" id="rating-button" onClick={() => handleVote('up')}>
        <p>
          <i className={'bi fs-4 ' + (upVoted ? 'bi-caret-up-fill' : 'bi-caret-up')}></i>
        </p>
      </button>
      <button className="btn" id="rating-button" onClick={() => handleVote('down')}>
        <p>
          <i className={'bi fs-4 ' + (downVoted ? 'bi-caret-down-fill' : 'bi-caret-down')}></i>
        </p>
      </button>
      <LoginModal show={show} handleClose={() => handleClose()} />
    </div>
  );
};

Voter.propTypes = {
  up: PropTypes.bool.isRequired,
  down: PropTypes.bool.isRequired,
  handleVote: PropTypes.func.isRequired,
};
