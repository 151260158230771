import PropTypes from 'prop-types';
import { RatingForm } from 'features/reviews/components/RatingForm';
import { Modal, Button } from 'components/Elements';
import React, { useState } from 'react';
import { useCreateReview } from 'features/reviews/api/createReview';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useReviews } from 'features/reviews/api/getReviews';
import { protectedResources } from 'config/authConfig';
import { useClasses } from 'features/courses/api/getClass';
import { getUser } from 'features/auth/api/getUser';
import { getReview } from 'features/reviews/api/getReview';

// "write a review" button and class rating form used for creating reviews
export const CreateReview = ({ courseName }) => {
  const [showReview, setShowReview] = useState(false);
  const [showInvalid, setShowInvalid] = useState(false);
  const createReviewMutation = useCreateReview({ courseName });
  const reviewsQuery = useReviews({ courseName });
  const classesQuery = useClasses({ className: courseName });
  let isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const request = { scopes: protectedResources.apiCourseRater.scopes.write, account: accounts[0] };

  // handles when the user submits class rating form
  const onSubmit = async (data) => {
    if (data['hoursPerWeek'] < 0) {
      alert('Hours per week cannot have a negative value.');
    } else if (data['midterms'] < 0) {
      alert('Midterms cannot have a negative value.');
    } else {
      instance.acquireTokenSilent(request).then(async (res) => {
        let accessToken = res.accessToken;
        await createReviewMutation.mutateAsync({
          accessToken,
          data,
          uniqueId: res.account.localAccountId,
          courseName,
        });
      });
    }
  };

  // handles when the user presses the "create a review" button
  const handleClick = async () => {
    // if user is logged in and hasn't created a review for the class yet, open the form modal
    if (isAuthenticated) {
      if (!reviewsQuery?.data[1] || !reviewsQuery?.data[1].length) {
        setShowReview(true);
      } else {
        let valid = true;
        const uniqueId = accounts[0].localAccountId;
        let userId = (await getUser({ uid: uniqueId }))?._id;
        let classObj = classesQuery?.data;
        for (let i = 0; i < classObj.reviews.length; i++) {
          let reviewObj = await getReview({ reviewId: classObj.reviews[i] });
          if (reviewObj.userId === userId) {
            setShowInvalid(true);
            return;
          }
        }
        if (valid) {
          setShowReview(true);
        }
      }
    }
  };

  return (
    <>
      {/* "write a review" button */}
      <div>
        <Button text="Write a review" variant="auth-check" onClick={handleClick} />
      </div>
      <div>
        {/* the form modal */}
        <Modal
          handleClose={() => setShowReview(false)}
          show={showReview && !createReviewMutation.isSuccess}
          title={'Rate: ' + courseName}
          size="lg"
        >
          <RatingForm courseName={courseName} onSubmit={onSubmit} />
        </Modal>
        {/* the modal for "user has already created a review" */}
        <Modal
          show={showInvalid}
          handleClose={() => setShowInvalid(false)}
          title="You have already reviewed this class."
          body="If you would like to create a new review, please delete the review that you have already made."
        />
      </div>
    </>
  );
};

CreateReview.propTypes = {
  courseName: PropTypes.string.isRequired,
};
