import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'features/courses/styles/Class.scss';
import { ReviewBox } from 'features/reviews/components/ReviewBox';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'components/Elements';
import { useReviews } from 'features/reviews/api/getReviews';
import { compareTwoStrings } from 'string-similarity';

// lists all reviews for a given class if there exists any
export const ReviewList = ({ courseName, sortingCategory, prof }) => {
  const [numReviewsDisplayed, setNumReviewsDisplayed] = useState(10);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const reviewsQuery = useReviews({ courseName });

  // sorts the reviews in 4 different ways (Best, Worst, New, and Old), best and worst determined by upvote/downvote ratio
  const sortReviews = (array, type) => {
    if (type === 'Best') {
      array.sort((a, b) => parseFloat(b.votes) - parseFloat(a.votes));
    } else if (type === 'Worst') {
      array.sort((a, b) => parseFloat(a.votes) - parseFloat(b.votes));
    } else if (type === 'New') {
      array.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    } else if (type === 'Old') {
      array.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    }
    return array;
  };

  const showProf = (array, prof) => {
    if (prof === 'All Professors') {
      return array;
    } else {
      return array.filter((revObj) => compareTwoStrings(prof, revObj.professor) >= 0.7);
    }
  };

  // loads 5 more reviews
  const loadMoreReviews = () => {
    let newNumberOfReviews = numReviewsDisplayed + 5;
    setNumReviewsDisplayed(newNumberOfReviews);
    if (reviewsQuery?.data[1] && newNumberOfReviews >= reviewsQuery?.data[0].length) {
      setShowLoadMoreButton(false);
    }
  };

  // loads reviews on page load
  useEffect(() => {
    if (reviewsQuery.isSuccess && reviewsQuery?.data?.length && reviewsQuery?.data[0].length > 10) {
      setShowLoadMoreButton(true);
    }
  }, []);

  // if reviews are loading, show a spinner
  if (reviewsQuery.isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  // otherwise if no reviews exist for the class yet, display "Be the first to write a review!"
  if (!reviewsQuery?.data[1] || !reviewsQuery?.data[1].length) {
    return (
      <div className="d-flex align-self-center my-5">
        <p>Be the first to write a review!</p>
      </div>
    );
  }

  // if reviews exist for the class, display them
  return (
    <div className="d-flex flex-column align-items-center mt-2 mb-4">
      {/* display all reviews in given sorted fashion */}
      {showProf(reviewsQuery?.data[0], prof).length ? (
        sortReviews(showProf(reviewsQuery?.data[0], prof), sortingCategory).map((value, i) => {
          if (i < numReviewsDisplayed) {
            return (
              <ReviewBox data={value} key={reviewsQuery.data[1][i]} id={reviewsQuery.data[1][i]} />
            );
          }
        })
      ) : (
        <div className="d-flex align-self-center my-5">
          <p>No reviews found.</p>
        </div>
      )}
      {showLoadMoreButton && (
        <div className="mt-3">
          <Button text="Load more" onClick={() => loadMoreReviews()} color="blue" />
        </div>
      )}
    </div>
  );
};

ReviewList.propTypes = {
  courseName: PropTypes.string.isRequired,
  sortingCategory: PropTypes.oneOf(['Best', 'Worst', 'New', 'Old']).isRequired,
  prof: PropTypes.string.isRequired,
};
