import { useMutation } from 'react-query';
import { queryClient } from 'lib/react-query';
import { protectedResources } from 'config/authConfig';
import { axios } from 'lib/axios';

// delete review from db
export const deleteReview = async ({ accessToken, courseName, reviewId, uniqueId }) => {
  const headers = {};
  headers['Authorization'] = `Bearer ${accessToken}`;
  const data = { uniqueId, courseName };

  return await axios.delete(protectedResources.apiCourseRater.endpoint + '/reviews/' + reviewId, {
    headers,
    data,
  });
};

// query function for deleting reviews
export const useDeleteReview = ({ config, courseName }) => {
  return useMutation({
    onMutate: async (deletedReview) => {
      await queryClient.cancelQueries(['reviews', courseName]);

      const previousReviews = queryClient.getQueryData(['reviews', courseName]);
      queryClient.setQueryData(
        ['reviews', courseName],
        previousReviews?.filter((review) => review.userId !== deletedReview.userId)
      );

      return { previousReviews };
    },
    onError: (_, __, context) => {
      if (context?.previousReviews) {
        queryClient.setQueryData(['reviews', courseName], context.previousReviews);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['reviews', courseName]);
    },
    ...config,
    mutationFn: deleteReview,
  });
};
