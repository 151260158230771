import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import PropTypes from 'prop-types';

// spinner component to signify a loading state
export const Spinner = ({ color = 'black', size = 'large' }) => {
  // returns a hexcode based on props
  const determineColor = () => {
    if (color === 'pink') {
      return '#e35f8d';
    } else if (color === 'blue') {
      return '#5fcfe3';
    } else if (color === 'yellow') {
      return '#e3b55f';
    } else {
      return '#000';
    }
  };
  return (
    <div
      className="spinner-border"
      role="status"
      style={{
        color: determineColor(),
        width: size === 'large' ? '3rem' : '',
        height: size === 'large' ? '3rem' : '',
      }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

// prop types
Spinner.propTypes = {
  color: PropTypes.oneOf(['pink', 'blue', 'yellow', 'black']),
  size: PropTypes.oneOf(['small', 'large']),
};
