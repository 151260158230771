import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

// returns a mongodb user object given a microsoft unique id
export async function getUser({ uid }) {
  return await axios.get('users/huid/' + uid);
}

// query object for getting users
export const getUsers = ({ uid, config }) => {
  return useQuery({
    queryKey: ['user', uid],
    queryFn: () => getUser({ uid }),
    ...config,
  });
};
