import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

// returns a class object from db from inputted class name
export async function getClass({ className }) {
  return await axios.get('classes/className/' + className.replaceAll(' ', '_'));
}

export const useClasses = ({ className, config }) => {
  return useQuery({
    queryKey: ['class', className],
    queryFn: () => getClass({ className }),
    ...config,
  });
};
