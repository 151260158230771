import * as z from 'zod';
import { useCreateReport } from 'features/reports/api/createReport';
import { Form, TextAreaField } from 'components/Form';
import { Modal, Button } from 'components/Elements';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const schema = z.object({
  body: z.string().min(3, 'Please state your reason.'),
});

// creating a report component, contains report button and form that popups in a modal
export const CreateReport = ({ reviewId }) => {
  const [show, setShow] = useState(false);
  const createReportMutation = useCreateReport({ reviewId });
  return (
    <>
      {/* flag icon button */}
      <div onClick={() => setShow(true)}>
        <i className="bi fs-5 bi-flag" />
      </div>
      {/* modal that pops up when user presses flag icon */}
      <Modal
        handleClose={() => setShow(false)}
        show={show && !createReportMutation.isSuccess}
        title="Report Review"
        showCloseButton={false}
      >
        <div className="w-100 h-100">
          {/* the form for creating a report */}
          <Form
            id="create-report"
            onSubmit={async (values) => {
              await createReportMutation.mutateAsync({ body: values.body, reviewId });
            }}
            schema={schema}
          >
            {({ register, formState }) => (
              <>
                <TextAreaField
                  label="Please state your reasoning for reporting this review."
                  marginTop={false}
                  error={formState.errors['body']}
                  registration={register('body')}
                />
                <div className="w-100 d-flex justify-content-center mt-2">
                  <Button text="Report" color="pink" type="submit" />
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
    </>
  );
};

CreateReport.propTypes = {
  reviewId: PropTypes.string.isRequired,
};
