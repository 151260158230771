import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'components/Form/styles/InputField.scss';
import { FieldWrapper } from 'components/Form/FieldWrapper';
import PropTypes from 'prop-types';

// input field for forms, allows user to type in and submit information
export const InputField = ({ type = 'text', label, error, registration }) => {
  return (
    <FieldWrapper label={label} error={error}>
      <input className="input-field" type={type} {...registration} />
    </FieldWrapper>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  registration: PropTypes.object.isRequired,
};