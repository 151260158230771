import { useMutation } from 'react-query';
import { protectedResources } from 'config/authConfig';
import { axios } from 'lib/axios';

// updates votes on a review object in db
export async function updateVotes({ accessToken, reviewId, value, uniqueId }) {
  const headers = {};
  headers['Authorization'] = `Bearer ${accessToken}`;
  const data = { votes: value, uniqueId };

  return await axios.patch(
    protectedResources.apiCourseRater.endpoint + '/reviews/' + reviewId,
    { data },
    {
      headers,
    }
  );
}

// query function for updating votes on a review object
export const useUpdateVotes = () => {
  return useMutation({
    onSuccess: () => {},
    mutationFn: updateVotes,
  });
};
