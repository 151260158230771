import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getAverages = async ({ className }) => {
  return await axios.get('classes/classname/' + className.replaceAll(' ', '_') + '/averages');
};

export const useAverages = ({ className, config }) => {
  return useQuery({
    queryKey: ['averages', className],
    queryFn: () => getAverages({ className }),
    ...config,
  });
};
