import { axios } from 'lib/axios';
import { protectedResources } from 'config/authConfig';

// creates a mongodb user object using azure authorization
export async function createUser({ accessToken, uid, email }) {
  const headers = {};
  headers['Authorization'] = `Bearer ${accessToken}`;
  const data = { uid, email };
  let options = {
    headers: headers,
    body: data ? JSON.stringify(data) : null,
  };

  return await axios.post(protectedResources.apiCourseRater.endpoint + '/users', data, {
    ...options,
  });
}
