import React, { useState } from 'react';
import 'App.scss';
import { getListOfClasses } from 'data/CourseParser';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { SmallSearchButton } from './SmallSearchButton';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { AutoComplete } from 'components/Elements';

// smaller search bar, used primarily on class pages
export const SmallSearchBar = () => {
  const [selected, setSelected] = useState('');
  const options = getListOfClasses();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  let navigate = useNavigate();

  // handles when a user submits text through search bar
  const handleSubmit = () => {
    const subject = selected.match(/[a-zA-Z]+/g);
    const number = selected.match(/\d+/g);
    navigate('/' + subject[0].toLowerCase() + '-' + number[0]);
  };

  // handles when user types into search bar
  function handleChange(value) {
    setSelected(value[0]);
  }

  // tablet/mobile version
  if (isTabletOrMobile) {
    return (
      <div className="d-flex flex-column p-3 align-self-center" id="home-div">
        <div className="d-flex align-self-center align-items-center">
          {/* the search bar that includes auto complete */}
          <AutoComplete
            options={options}
            selected={selected}
            handleChange={(value) => handleChange(value)}
            size="small"
            placeholder="Search other classes"
          />
          {/* the search bar "enter" button */}
          <div className="ms-2">
            <SmallSearchButton type="submit" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    );
  }

  // desktop version
  return (
    <div className="d-flex flex-column p-3 min-vw-50 min-vh-50" id="home-div">
      <div className="d-flex flex-column align-self-center p-3 w-100">
        <div className="d-flex align-self-center align-items-center">
          {/* the search bar that includes auto complete */}
          <AutoComplete
            options={options}
            selected={selected}
            handleChange={(value) => handleChange(value)}
            size="small"
            placeholder="Search other classes"
          />
          {/* the search bar "enter" button */}
          <div className="ms-2">
            <SmallSearchButton type="submit" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};
