import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'components/Form/styles/DatalistInputField.scss';
import { FieldWrapper } from 'components/Form/FieldWrapper';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

// data list input field for forms, meant for users to be able to write in a custom option but also for there to be a dropdown
export const DatalistInputField = ({ type = 'text', label, error, registration, data }) => {
  return (
    <FieldWrapper label={label} error={error}>
      <>
        <input className="input-field" type={type} list="data" {...registration} />
        <datalist id="data">
          {data.map((item) => (
            <option key={nanoid()} value={item} />
          ))}
        </datalist>
      </>
    </FieldWrapper>
  );
};

DatalistInputField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  registration: PropTypes.object.isRequired,
};
