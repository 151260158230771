import React from 'react';
import './MainLayout.scss';
import LoginLogoutButton from 'features/auth/components/LoginLogoutButton';
import PropTypes from 'prop-types';

// navigation bar
const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid px-5 pt-2">
        <a className="navbar-brand fw-bold" href="/">
          course-rater
        </a>
        <button
          className="navbar-toggler"
          id="hamburger-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link text-black" id="navLink" aria-current="page" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-black" id="navLink" href="/schedule">
                Rate My Schedule
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-black" id="navLink" href="/b20">
                B20
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-black" id="navLink" href="/about">
                About
              </a>
            </li>
          </ul>
          <LoginLogoutButton />
        </div>
      </div>
    </nav>
  );
};

// main layout, just puts nav bar on page
export const MainLayout = ({ children }) => {
  return (
    <div className="mw-100 min-vh-100">
      <NavBar />
      <main style={{ flex: '1 1 0%' }}>{children}</main>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
