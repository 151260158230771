import { Button } from 'components/Elements';
import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDeleteReview } from 'features/reviews/api/deleteReview';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from 'config/authConfig';

// deleting a review component, contains the trash can icon button and a confirmation popup
export const DeleteReview = ({ reviewId, courseName }) => {
  const [show, setShow] = useState(false);
  const { accounts, instance } = useMsal();
  const request = { scopes: protectedResources.apiCourseRater.scopes.write, account: accounts[0] };

  const deleteReviewMutation = useDeleteReview({ courseName });

  return (
    <>
      {/* trash can icon button */}
      <div onClick={() => setShow(true)}>
        <i className="bi bi-trash"></i>
      </div>
      {/* the confirmation modal */}
      <Modal show={show && !deleteReviewMutation.isSuccess} onHide={() => setShow(!show)} centered>
        <Modal.Header style={{ border: 'none' }} closeButton>
          <Modal.Title>Delete your review?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center" style={{ border: 'none' }}>
          This will permanently remove it from the website.
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          <Button text="No" color="white" onClick={() => setShow(false)} />
          <Button
            text="Yes"
            color="red"
            onClick={async () =>
              instance.acquireTokenSilent(request).then(async (res) => {
                let accessToken = res.accessToken;
                await deleteReviewMutation.mutateAsync({
                  accessToken,
                  reviewId,
                  uniqueId: res.account.localAccountId,
                  courseName,
                });
              })
            }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

DeleteReview.propTypes = {
  reviewId: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
};
