import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useMediaQuery } from 'react-responsive';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { NumberBox, Spinner } from 'components/Elements';
import { useAverages } from 'features/courses/api/getAverages';

// info bar, the bar at top of each class page that displays different statistics like # of midterms and average hours per week
export const InfoBar = ({ courseName }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  // uses averages query to get class averages
  const averagesQuery = useAverages({ className: courseName });

  // if loading, display a loading spinner
  if (averagesQuery.isLoading) {
    return (
      <div className="w-100 h-50 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  // if there are no averages, then return null
  if (!averagesQuery?.data?.rating) {
    return null;
  }

  // mobile version
  if (isMobile) {
    return (
      <div
        className="d-flex flex-column h-25 align-self-center justify-content-center py-2"
        style={{
          width: '100%',
          border: '3px solid black',
          boxShadow: '4px 4px #000000',
          borderRadius: '10px',
        }}
      >
        {/* displays the info bar as a 2 row 3 column grid */}
        <Row className="d-flex justify-content-between w-75 align-self-center mb-2">
          {/* the number boxes*/}
          <NumberBox
            number={averagesQuery.data.midterms}
            desc="Midterms"
            isLoading={averagesQuery.isLoading}
          />
          <NumberBox
            number={averagesQuery.data.hoursPerWeek}
            desc="Hours per Week"
            isLoading={averagesQuery.isLoading}
          />
          <NumberBox
            number={(averagesQuery.data.isRecommended * 100).toString() + '%'}
            desc="Would recommend"
            isLoading={averagesQuery.isLoading}
          />
        </Row>
        <Row className="d-flex justify-content-between w-75 align-self-center">
          <NumberBox
            number={averagesQuery.data.rating}
            desc="Rating"
            isLoading={averagesQuery.isLoading}
          />
          <NumberBox
            number={averagesQuery.data.difficulty}
            desc="Difficulty"
            isLoading={averagesQuery.isLoading}
          />
          <NumberBox
            number={Math.round(averagesQuery.data.hasFinal) ? 'Yes' : 'No'}
            desc="Final"
            isLoading={averagesQuery.isLoading}
          />
        </Row>
      </div>
    );
  }
  // desktop and tablet version
  return (
    <div
      className={
        'd-flex flex-wrap h-25 align-self-center justify-content-center gap-5 py-3 ' +
        (isTabletOrMobile ? '' : 'w-50')
      }
      style={{ border: '3px solid black', boxShadow: '4px 4px #000000', borderRadius: '10px' }}
    >
      {/* displays the numbers all in a row */}
      <NumberBox
        number={averagesQuery.data.midterms}
        desc="Midterms"
        isLoading={averagesQuery.isLoading}
      />
      <NumberBox
        number={averagesQuery.data.hoursPerWeek}
        desc="Hours per Week"
        isLoading={averagesQuery.isLoading}
      />
      <NumberBox
        number={(averagesQuery.data.isRecommended * 100).toString() + '%'}
        desc="Would recommend"
        isLoading={averagesQuery.isLoading}
      />
      <NumberBox
        number={averagesQuery.data.rating}
        desc="Rating"
        isLoading={averagesQuery.isLoading}
      />
      <NumberBox
        number={averagesQuery.data.difficulty}
        desc="Difficulty"
        isLoading={averagesQuery.isLoading}
      />
      <NumberBox
        number={Math.round(averagesQuery.data.hasFinal) ? 'Yes' : 'No'}
        desc="Final"
        isLoading={averagesQuery.isLoading}
      />
    </div>
  );
};

InfoBar.propTypes = {
  courseName: PropTypes.string.isRequired,
};
