import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { getReview } from 'features/reviews/api/getReview';

// gets list of review objects given a class
export const getReviews = async ({ courseName }) => {
  const revIDs = (await axios.get('classes/className/' + courseName.replaceAll(' ', '_')))?.reviews;
  let reviews = [];
  if (revIDs) {
    for (let i in revIDs) {
      let review = await getReview({ reviewId: revIDs[i] });
      reviews.push(review);
    }
  }
  return [reviews, revIDs];
};

// query function for fetching reviews for a class
export const useReviews = ({ courseName, config }) => {
  return useQuery({
    queryKey: ['reviews', courseName],
    queryFn: () => getReviews({ courseName }),
    ...config,
  });
};
