// gets the number of credits the class it given the class name
import Axios from 'axios';
import { useQuery } from 'react-query';

// gets credits for a class
export async function getCredits({ className }) {
  let [subject, courseNum] = className.toUpperCase().split(' ');
  const res = await Axios.get(
    `https://api.purdue.io/odata/Courses?Classes&$filter=Subject/Abbreviation eq '` +
      subject +
      `' and Number eq '` +
      courseNum +
      "'"
  );
  if (res.data.value.length) {
    return res.data.value[0]['CreditHours'];
  } else {
    return 'N/A';
  }
}

export const useCredits = ({ className, config }) => {
  return useQuery({
    queryKey: ['credits', className],
    queryFn: () => getCredits({ className }),
    ...config,
  });
};
