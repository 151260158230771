import { getUser } from 'features/auth/api/getUser';
import { protectedResources } from 'config/authConfig';
import { axios } from 'lib/axios';

// adds a review to the user's voting lists in db based on their vote
export async function patchUserVotes({ accessToken, uid, reviewId, vote }) {
  let user = await getUser({ uid });
  let id = user._id;
  let upvotedReviews = user.upvotedReviews;
  let downvotedReviews = user.downvotedReviews;
  if (upvotedReviews.includes(reviewId)) {
    upvotedReviews.splice(upvotedReviews.indexOf(reviewId), 1);
  }
  if (downvotedReviews.includes(reviewId)) {
    downvotedReviews.splice(downvotedReviews.indexOf(reviewId), 1);
  }
  if (vote === 'up') {
    upvotedReviews.push(reviewId);
  } else if (vote === 'down') {
    downvotedReviews.push(reviewId);
  }

  const headers = {};
  headers['Authorization'] = `Bearer ${accessToken}`;
  const data = {
    upvotedReviews,
    downvotedReviews,
  };

  return await axios.patch(
    protectedResources.apiCourseRater.endpoint + '/users/' + id,
    { data },
    {
      headers,
    }
  );
}
