import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/custom.scss';
import AppProvider from './providers/app';
import { AppRoutes } from 'routes';

export default function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}
