import React from 'react';
import { Button, Spinner } from 'components/Elements';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'config/authConfig';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { queryClient } from 'lib/react-query';
import { QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types';

// if there is an error, fall back to this page
const ErrorFallback = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center justify-content-center text-center mt-5"
      role="alert"
    >
      <h2 className="text-lg fw-bold">Oops, something went wrong :( </h2>
      <Button
        className="mt-4"
        onClick={() => window.location.assign(window.location.origin)}
        text="Refresh"
      />
    </div>
  );
};

/**
 * MSAL should be instantiated outside the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// the providers for the app
// react suspense for loading, error boundary for error checking, helmet provider for head component
// query client provider for react-query, msal provider for microsoft authentication, browser router for routes
function AppProvider({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <MsalProvider instance={msalInstance}>
              <BrowserRouter>{children}</BrowserRouter>
            </MsalProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
}

// prop types
AppProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AppProvider;
