import React from 'react';
import { Modal, Button } from 'components/Elements';
import { useMsal } from '@azure/msal-react';
import { protectedResources } from 'config/authConfig';
import { isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';
import { createUser } from 'features/auth/api/createUser';

// the login modal, a popup with a  button that allows users to sign in through Microsoft using their Purdue email
function LoginModal({ show, handleClose }) {
  // gets the token silently, in other words it tries to get a token without disturbing user
  // const { login, error } = useMsalAuthentication(InteractionType.Silent, request);
  const { instance, accounts } = useMsal();
  const request = { scopes: protectedResources.apiCourseRater.scopes.write, account: accounts[0] };

  // handles when the user decides to log in with Microsoft
  const handleLogin = () => {
    // IOS only works on a login redirect call
    if (isIOS) {
      instance
        .acquireTokenRedirect(request)
        .then(async function (res) {
          // Acquire token interactive success
          let accessToken = res?.accessToken;
          // Call your API with token
          await createUser({
            accessToken,
            uid: res?.account.localAccountId,
            email: res?.account.username,
          });
        })
        .catch(function (error) {
          // Acquire token interactive failure
          console.log(error);
        });
    } else {
      // uses a popup for every other device
      instance
        .acquireTokenPopup(request)
        .then(async function (res) {
          // Acquire token interactive success
          let accessToken = res.accessToken;
          // Call your API with token
          await createUser({
            accessToken,
            uid: res.account.localAccountId,
            email: res.account.username,
          });
        })
        .catch(function (error) {
          // Acquire token interactive failure
          console.log(error);
        });
    }
  };

  return (
    <>
      {/* modal that contains the microsoft login button */}
      <Modal
        handleClose={() => handleClose()}
        show={show}
        title="Login"
        showCloseButton={false}
        footerText="Please login with your Purdue email"
      >
        <div className="w-100 h-100 d-flex justify-content-center mb-2">
          {/* the microsoft login button */}
          <Button
            text="Sign in with Microsoft"
            color="white"
            variant="microsoft"
            onClick={() => handleLogin()}
          />
        </div>
      </Modal>
    </>
  );
}

LoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LoginModal;
