import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './Dropdown.scss';
import PropTypes from 'prop-types';

// dropdown component, primarily used on class page as a filtering tool
export const Dropdown = ({ onChangeValue, value, list, color = 'blue', showSortBy = false }) => {
  // handles when a user uses dropdown menu and clicks on an option
  const handleClick = (value) => {
    onChangeValue(value.target.text);
  };

  // function to set background color, output changes based on color prop from parent
  const determineBackgroundColor = () => {
    if (color === 'pink') {
      return '#e35f8d';
    } else if (color === 'blue') {
      return '#5fcfe3';
    } else if (color === 'yellow') {
      return '#e3b55f';
    } else if (color === 'white') {
      return '#fff';
    } else {
      return '#5fcfe3';
    }
  };

  return (
    <div className="dropdown">
      {/* the button that displays and triggers drop down*/}
      <button
        className="btn dropdown-toggle"
        type="button"
        id="dropdownButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ backgroundColor: determineBackgroundColor() }}
      >
        {showSortBy && 'Sort by: '}
        {value}
      </button>
      {/* the menu that appears as the "dropdown" */}
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {list.map((value, i) => (
          <li key={i} onClick={(value) => handleClick(value)}>
            <a className="dropdown-item">{value}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  onChangeValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  color: PropTypes.oneOf(['pink', 'blue', 'yellow', 'white']),
  showSortBy: PropTypes.bool,
};
