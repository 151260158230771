import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './Button.scss';
import 'App.scss';
import { ReactComponent as MicrosoftLogo } from 'assets/microsoft-logo.svg';
import { ReactComponent as RedditLogo } from 'assets/reddit-logo-2436.svg';
import { ReactComponent as PurdueLogo } from 'assets/Purdue_Boilermakers_logo.svg';
import LoginModal from 'features/auth/components/LoginModal';
import { useIsAuthenticated } from '@azure/msal-react';
import PropTypes from 'prop-types';

// button that can take on 4 different colors: pink (default), blue, yellow, and white
// variations include and auth-check button and a microsoft login button
export const Button = ({ type = 'button', text, onClick, color = 'pink', variant }) => {
  // show state controls the login modal (the popup that prompts the user to sign in with microsoft)
  const [show, setShow] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  // returns a hexcode based on props
  const determineBackgroundColor = () => {
    if (color === 'pink') {
      return '#e35f8d';
    } else if (color === 'blue') {
      return '#5fcfe3';
    } else if (color === 'yellow') {
      return '#e3b55f';
    } else if (color === 'white') {
      return '#fff';
    } else if (color === 'red') {
      return '#890620';
    } else {
      return '#e35f8d';
    }
  };

  // sets show state
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // if microsoft, show button as white with microsoft logo
  if (variant === 'microsoft') {
    return (
      <>
        <button
          type={type}
          className="btn btn-primary"
          id="button"
          style={{ backgroundColor: '#ffffff' }}
          onClick={onClick}
        >
          <MicrosoftLogo />
          {'  ' + text}
        </button>
      </>
    );
  } // if reddit, show button as white with purdue logo
  else if (variant === 'reddit') {
    return (
      <>
        <button
          type={type}
          className="btn btn-primary"
          id="button"
          style={{ backgroundColor: '#ffffff' }}
          onClick={onClick}
        >
          <RedditLogo />
          {'  ' + text}
        </button>
      </>
    );
  } // if microsoft, show button as white with microsoft logo
  else if (variant === 'purdue') {
    return (
      <>
        <button
          type={type}
          className="btn btn-primary"
          id="button"
          style={{ backgroundColor: '#ffffff' }}
          onClick={onClick}
        >
          <PurdueLogo />
          {'  ' + text}
        </button>
      </>
    );
  }
  // if auth-check, first check if user is logged in
  // if they are, let user have access to button
  else if (variant === 'auth-check') {
    if (isAuthenticated) {
      return (
        <>
          <button
            type={type}
            className="btn btn-primary"
            id="button"
            style={{ backgroundColor: determineBackgroundColor() }}
            onClick={onClick}
          >
            {text}
          </button>
        </>
      );
    }
    // if the user is not authenticated, prompt login pop up so that user can sign in
    else {
      return (
        <>
          <button
            type={type}
            className="btn btn-primary"
            id="button"
            style={{ backgroundColor: determineBackgroundColor() }}
            onClick={handleShow}
          >
            {text}
          </button>
          <LoginModal show={show} handleClose={() => handleClose()} />
        </>
      );
    }
  }
  // default case: return normal button
  return (
    <>
      <button
        type={type}
        className="btn btn-primary"
        id="button"
        style={{ backgroundColor: determineBackgroundColor() }}
        onClick={onClick}
      >
        <span
          style={{
            color: color === 'red' ? '#fff' : '#000',
            fontWeight: color === 'red' ? 'bold' : '',
          }}
        >
          {text}
        </span>
      </button>
    </>
  );
};

// prop types
Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.oneOf(['pink', 'blue', 'yellow', 'white', 'red']),
  variant: PropTypes.oneOf(['microsoft', 'auth-check', 'reddit', 'purdue']),
};
