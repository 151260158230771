import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'components/Form/styles/TextAreaField.scss';
import { FieldWrapper } from 'components/Form/FieldWrapper';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// text area field for forms, like input field but larger
export const TextAreaField = ({ label, registration, error, marginTop = true }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <FieldWrapper label={label} error={error} marginTop={marginTop}>
      <textarea
        className="textarea-field"
        style={{ height: isTabletOrMobile ? '200px' : '100px' }}
        {...registration}
      />
    </FieldWrapper>
  );
};

TextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  registration: PropTypes.object.isRequired,
  marginTop: PropTypes.bool,
};
