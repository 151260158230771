import React from 'react';
import 'App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { nanoid } from 'nanoid';

// title font sizes
const titleFontSizes = {
  sm: '1.1em',
  md: '1.15em',
};

// body text font sizes
const bodyFontSizes = {
  sm: '1em',
  md: '1.05em',
};

// datatable component, can have 2 columns for values
export const DataTable = ({
  title,
  left,
  right,
  leftRightTitles,
  headerColor = 'pink',
  isLoading,
  type = 'Normal',
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  // determines the color of the header, output changes with the props provided by parent, default color is pink
  const determineHeaderColor = () => {
    if (headerColor === 'pink') {
      return '#e35f8d';
    } else if (headerColor === 'blue') {
      return '#5fcfe3';
    } else if (headerColor === 'yellow') {
      return '#e3b55f';
    }
  };

  return (
    <div
      className={'d-flex flex-column' + (isMobile ? ' w-100' : '')}
      style={{ border: '3px solid black', boxShadow: '4px 4px #000000', borderRadius: '10px' }}
    >
      <div
        style={{
          backgroundColor: determineHeaderColor(),
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          display: 'block',
          borderBottom: '3px solid black',
          color: 'white',
          fontSize: isMobile ? titleFontSizes['sm'] : titleFontSizes['md'],
        }}
        className="d-flex px-3 py-1 align-items-center fw-bold"
      >
        {/* title of the data table */}
        {title}
      </div>
      <div className="px-3 my-1">
        <div
          className={
            'd-flex ' +
            (isTabletOrMobile ? 'justify-content-between' : 'justify-content-lg-between')
          }
        >
          {/* the two titles that appear for the left and right columns */}
          <p
            className="fw-bold"
            style={{ fontSize: isMobile ? bodyFontSizes['sm'] : bodyFontSizes['md'] }}
          >
            {leftRightTitles[0]}
          </p>
          <p
            className="fw-bold"
            style={{ fontSize: isMobile ? bodyFontSizes['sm'] : bodyFontSizes['md'] }}
          >
            {leftRightTitles[1]}
          </p>
        </div>
        {/* the body of the data table*/}
        {left.map((value, i) => {
          return (
            <div
              className={
                'd-flex placeholder-glow ' +
                (isTabletOrMobile ? 'justify-content-between' : 'justify-content-lg-between')
              }
              key={nanoid()}
            >
              <p
                style={{ fontSize: isMobile ? bodyFontSizes['sm'] : bodyFontSizes['md'] }}
                className={isLoading ? 'placeholder' : ''}
              >
                {type === 'Scores' ? i + 1 + '. ' : ''}
                {value}
              </p>
              <p
                style={{ fontSize: isMobile ? bodyFontSizes['sm'] : bodyFontSizes['md'] }}
                className={isLoading ? 'placeholder' : ''}
              >
                {isNaN(right[i]) ? 'N/A' : right[i]}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DataTable.propTypes = {
  title: PropTypes.string.isRequired,
  left: PropTypes.arrayOf(PropTypes.string).isRequired,
  right: PropTypes.arrayOf(PropTypes.number || PropTypes.string).isRequired,
  leftRightTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  headerColor: PropTypes.oneOf(['pink', 'blue', 'yellow']),
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['Normal', 'Scores']),
};
