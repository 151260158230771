import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'components/Elements/Button/Button.scss';
import PropTypes from 'prop-types';

// the small search bar button
export const SmallSearchButton = ({ type, onClick, color = 'pink' }) => {
  // determines the background color
  function determineBackgroundColor() {
    if (color === 'pink') {
      return '#e35f8d';
    } else if (color === 'blue') {
      return '#5fcfe3';
    } else if (color === 'yellow') {
      return '#e3b55f';
    } else {
      return '#e35f8d';
    }
  }

  return (
    <div>
      {/* button with the magnifying class icon */}
      <button
        type={type}
        className="btn btn-primary"
        id="button"
        style={{ backgroundColor: determineBackgroundColor() }}
        onClick={onClick}
      >
        <i className="bi bi-search fs-5"></i>
      </button>
    </div>
  );
};

SmallSearchButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['pink', 'blue', 'yellow']),
};
