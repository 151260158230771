import React from 'react';
import { Button } from 'components/Elements/Button/Button';
import BSModal from 'react-bootstrap/Modal'; // BS stands for Bootstrap
import PropTypes from 'prop-types';
import 'App.scss';

// custom modal component
export const Modal = ({
  show,
  handleClose,
  title,
  children,
  showCloseButton = true,
  body,
  footerText = '',
  size = '',
}) => {
  return (
    <>
      <BSModal show={show} onHide={handleClose} size={size} centered>
        <BSModal.Header style={{ border: 'none' }} closeButton>
          <BSModal.Title>{title}</BSModal.Title>
        </BSModal.Header>
        <BSModal.Body className="d-flex justify-content-center" style={{ border: 'none' }}>
          {children}
          {body}
        </BSModal.Body>
        <BSModal.Footer style={{ border: 'none' }}>
          {footerText !== '' ? footerText : null}
          {showCloseButton && <Button text="Close" color="white" onClick={handleClose} />}
        </BSModal.Footer>
      </BSModal>
    </>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  body: PropTypes.string,
  showCloseButton: PropTypes.bool,
  footerText: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
};
