import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js'; // IF REMOVED CHART BREAKS
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

// options for the bar graph, customizes the graph
const options = {
  // Core options
  aspectRatio: 5 / 3,
  elements: {
    line: {
      fill: false,
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Grades',
        font: function (context) {
          const w = context.chart.width;
          return {
            size: w < 512 ? 12 : 14,
            weight: 'bold',
          };
        },
        color: 'black',
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Percentage of Students',
        font: function (context) {
          const w = context.chart.width;
          return {
            size: w < 512 ? 12 : 14,
            weight: 'bold',
          };
        },
        color: 'black',
      },
    },
  },
};

// bar graph component, used to display grade data
export const BarGraph = ({ graphData, type }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const gradeValues = [];
  Object.values(graphData).forEach((element) => {
    gradeValues.push(parseFloat(element));
  });
  if (type === 'schedule') {
    options.scales.x.title.text = 'Classes';
    options.scales.y.title.text = 'Average GPA';
  } else if (type === 'class') {
    options.scales.x.title.text = 'Grades';
    options.scales.y.title.text = 'Percentage of Students';
  }
  // mobile version, graph orientation is horizontal rather than vertical (desktop version is vertical)
  if (isMobile) {
    const data = {
      labels: Object.keys(graphData),
      datasets: [
        {
          label: 'Grades',
          data: gradeValues,
          backgroundColor: '#e3b55f',
          borderColor: '#000',
          borderWidth: 2,
          borderRadius: 5,
        },
      ],
    };
    options.indexAxis = 'y';
    options.aspectRatio = 3 / 5;
    let temp = options.scales.x.title.text;
    options.scales.x.title.text = options.scales.y.title.text;
    options.scales.y.title.text = temp;
    return (
      <div style={{ minWidth: '75vw', minHeight: '50vh' }}>
        <Bar data={data} options={options} />
      </div>
    );
  }
  // initializes the data object to be used for the bar graph, customizes colors among other design choices
  const data = {
    labels: Object.keys(graphData),
    datasets: [
      {
        label: 'Grades',
        data: gradeValues,
        backgroundColor: '#e3b55f',
        borderColor: '#000',
        borderWidth: 3,
        borderRadius: 5,
      },
    ],
  };

  return (
    <div className="w-100 h-100">
      <Bar data={data} options={options} />
    </div>
  );
};

BarGraph.propTypes = {
  graphData: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['class', 'schedule']).isRequired,
};
