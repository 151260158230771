import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'components/Form/styles/RatingSlider.scss';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { FieldWrapper } from 'components/Form/FieldWrapper';

// different slider styles
const sliderStyle = {
  mobile: {
    border: '2px solid black',
    borderRadius: '6px',
    height: '30px',
    width: '60px',
    backgroundColor: ' #E4E4E4',
  },
  tabletDesktop: {
    border: '2px solid black',
    borderRadius: '6px',
    height: '40px',
    width: '80px',
    backgroundColor: ' #E4E4E4',
  },
};

// rating slider used for forms, allows for 5 options, colors are pre-set but descriptions can be customized
export const RatingSlider = ({ onSliderChange, ratingsArray, rating, error }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const [ratingText, setRatingText] = useState('Choose a value');
  const [bgColors, setBgColors] = useState(['#E4E4E4', '#E4E4E4', '#E4E4E4', '#E4E4E4', '#E4E4E4']);

  // changes colors of certain squares based on value and inputted opacity value
  const changeColors = (value, opacity) => {
    let activeColors = [
      'rgba(228, 228, 228,' + opacity + ')',
      'rgba(228, 228, 228,' + opacity + ')',
      'rgba(228, 228, 228,' + opacity + ')',
      'rgba(228, 228, 228,' + opacity + ')',
      'rgba(228, 228, 228,' + opacity + ')',
    ];
    let colors = [
      'rgba(227, 95, 141, ' + opacity + ')',
      'rgba(227, 138, 118,' + opacity + ')',
      'rgba(227, 181, 95,' + opacity + ')',
      'rgba(161, 194, 161,' + opacity + ')',
      'rgba(95, 207, 227,' + opacity + ')',
    ];
    for (let i = 0; i < value; i++) {
      activeColors[i] = colors[i];
    }
    setBgColors(activeColors);
  };

  // handles the slider, whenever a user clicks on a value
  const handleSlider = (value) => {
    onSliderChange(value);
    changeColors(value, 1);
    if (isTabletOrMobile) {
      setRatingText(ratingsArray[value - 1]);
    }
  };

  // handles when the cursor moves over the slider
  const handleMouseEnter = (value) => {
    setRatingText(ratingsArray[value - 1]);
    changeColors(value, 0.7);
  };

  // handles when the cursor leaves the slider
  const handleMouseLeave = () => {
    let activeColors = [
      'rgba(228, 228, 228,1)',
      'rgba(228, 228, 228,1)',
      'rgba(228, 228, 228,1)',
      'rgba(228, 228, 228,1)',
      'rgba(228, 228, 228,1)',
    ];
    let colors = [
      'rgba(227, 95, 141, 1)',
      'rgba(227, 138, 118,1)',
      'rgba(227, 181, 95,1)',
      'rgba(161, 194, 161,1)',
      'rgba(95, 207, 227,1)',
    ];
    for (let i = 0; i < rating; i++) {
      activeColors[i] = colors[i];
    }
    // if there is a rating, then display slider as such, otherwise, show slider as empty (all grey)
    if (rating) {
      setBgColors(activeColors);
      setRatingText(ratingsArray[rating - 1]);
    } else {
      setBgColors([
        'rgb(228, 228, 228)',
        'rgb(228, 228, 228)',
        'rgb(228, 228, 228)',
        'rgb(228, 228, 228)',
        'rgb(228, 228, 228)',
      ]);
      setRatingText('Choose a value');
    }
  };

  return (
    <FieldWrapper error={error}>
      <div className="d-flex flex-column align-items-center align-self-center">
        <div className="d-flex gap-1">
          {/* a single box in the slider */}
          <div
            id="slider-1"
            style={{
              ...sliderStyle[isMobile ? 'mobile' : 'tabletDesktop'],
              backgroundColor: bgColors[0],
            }}
            onClick={() => handleSlider(1)}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          />
          <div
            id="slider-2"
            style={{
              ...sliderStyle[isMobile ? 'mobile' : 'tabletDesktop'],
              backgroundColor: bgColors[1],
            }}
            onClick={() => handleSlider(2)}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          />
          <div
            id="slider-3"
            style={{
              ...sliderStyle[isMobile ? 'mobile' : 'tabletDesktop'],
              backgroundColor: bgColors[2],
            }}
            onClick={() => handleSlider(3)}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={handleMouseLeave}
          />
          <div
            id="slider-4"
            style={{
              ...sliderStyle[isMobile ? 'mobile' : 'tabletDesktop'],
              backgroundColor: bgColors[3],
            }}
            onClick={() => handleSlider(4)}
            onMouseEnter={() => handleMouseEnter(4)}
            onMouseLeave={handleMouseLeave}
          />
          <div
            id="slider-5"
            style={{
              ...sliderStyle[isMobile ? 'mobile' : 'tabletDesktop'],
              backgroundColor: bgColors[4],
            }}
            onClick={() => handleSlider(5)}
            onMouseEnter={() => handleMouseEnter(5)}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <p className="d-flex align-self-center mt-2 fw-bold">{ratingText}</p>
      </div>
    </FieldWrapper>
  );
};

RatingSlider.propTypes = {
  onSliderChange: PropTypes.func.isRequired,
  ratingsArray: PropTypes.array.isRequired,
  rating: PropTypes.number,
  error: PropTypes.object,
};
