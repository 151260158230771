import React from 'react';
import 'App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Button } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Head } from 'components/Head';

// 404 not found page
export const NotFound = () => {
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  return (
    <div>
      <Head
        title="Error: Page Not Found"
        description="Error 404, Page Not Found"
        name="Course Rater"
        type="Error 404"
      />
      <div
        className="d-flex flex-column align-items-center justify-content-center text-center"
        style={{ height: '90vh' }}
      >
        <div className="lh-1 w-75 d-flex flex-column align-items-center justify-content-center text-center">
          <p className="fw-bold" style={{ fontSize: '3.3em' }}>
            Error
          </p>
          <p style={{ fontSize: isMobile ? '1.3em' : '1.4em' }}>
            This page does not exist, or does not work.
          </p>
        </div>
        <p className="fw-bold" style={{ fontSize: isMobile ? '12em' : '15em' }}>
          404
        </p>
        <Button onClick={() => navigate('/')} text="Back to Homepage" />
      </div>
    </div>
  );
};
