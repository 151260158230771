import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import {
  englishDataset,
  englishRecommendedTransformers,
  RegExpMatcher,
  TextCensor,
} from 'obscenity';
import { protectedResources } from 'config/authConfig';
import { getUser } from 'features/auth/api/getUser';
import { queryClient } from 'lib/react-query';
import { axios } from 'lib/axios';

// creates a review
export const createReview = async ({ accessToken, data, uniqueId, courseName }) => {
  data['userId'] = (await getUser({ uid: uniqueId }))?._id;
  data['className'] = courseName;
  data['hasFinal'] = str2bool(data['hasFinal']);
  data['hasLabs'] = str2bool(data['hasLabs']);
  data['isRecommended'] = str2bool(data['isRecommended']);
  data['forCredit'] = str2bool(data['forCredit']);
  data['hasTextbooks'] = str2bool(data['hasTextbooks']);
  data['attendanceRequired'] = str2bool(data['attendanceRequired']);
  data['review'] = profanityChecker(data['review']);
  data['professor'] = profanityChecker(data['professor']);
  const headers = {};
  headers['Authorization'] = `Bearer ${accessToken}`;
  let options = {
    headers: headers,
    body: data ? JSON.stringify(data) : null,
  };
  return await axios.post(protectedResources.apiCourseRater.endpoint + '/reviews', data, {
    ...options,
  });
};

// query component for creating reviews
export const useCreateReview = ({ config, courseName }) => {
  return useMutation({
    onMutate: async (newReview) => {
      await queryClient.cancelQueries(['reviews', courseName]);
      const previousReviews = queryClient.getQueryData(['reviews', courseName]);
      queryClient.setQueryData(['reviews', courseName], [...(previousReviews || []), newReview]);
      return { previousReviews };
    },
    onError: (_, __, context) => {
      if (context?.previousReviews) {
        queryClient.setQueryData(['reviews', courseName], context.previousReviews);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['reviews', courseName]);
    },
    ...config,
    mutationFn: createReview,
  });
};

useCreateReview.propTypes = {
  config: PropTypes.any,
  reviewId: PropTypes.string.isRequired,
};

// converts string to boolean
const str2bool = (value) => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  return value;
};

// checks and replaces profanity on review and professor
const profanityChecker = (input) => {
  const matcher = new RegExpMatcher({
    ...englishDataset.build(),
    ...englishRecommendedTransformers,
  });
  const censor = new TextCensor();
  const matches = matcher.getAllMatches(input);
  return censor.applyTo(input, matches);
};
