import React, { useState } from 'react';
import 'App.scss';
import { getListOfClasses } from 'data/CourseParser.js';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Button, AutoComplete, StatusBar } from 'components/Elements';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Head } from 'components/Head';

// Home/Landing page
export const Home = () => {
  const [selected, setSelected] = useState('');
  const options = getListOfClasses();
  let navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  // handles when a user submits a class
  const handleSubmit = () => {
    const subject = selected.match(/[a-zA-Z]+/g);
    const number = selected.match(/\d+/g);
    navigate('/' + subject[0].toLowerCase() + '-' + number[0]);
  };

  // handles when the user selects an item from the autocomplete dropdown
  function handleChange(value) {
    setSelected(value[0]);
  }

  // styles for the component
  const styles = () => {
    let style = {};
    style['width'] = '';
    style['titleFontSize'] = '6em';
    if (isTabletOrMobile) {
      style['width'] = 'w-75';
      style['titleFontSize'] = '3em';
      if (isMobile) {
        style['titleFontSize'] = '2em';
        style['width'] = 'w-100';
      }
    }
    return style;
  };

  // list of classes that could be placeholders, picks one at random to display
  const placeholderClasses = [
    'ECE20001',
    'MA26100',
    'CS18000',
    'AAE20300',
    'PHYS17200',
    'ENGR13100',
    'ME20000',
    'CHE20500',
  ];
  const item = placeholderClasses[Math.floor(Math.random() * placeholderClasses.length)];

  return (
    <div>
      <Head
        title="Course Rater"
        description="Find and rate your Purdue courses. See insightful reviews and grades for all your Purdue courses."
        name="Course Rater"
        type="Home"
      />
      <div
        className="d-flex flex-column p-3 min-vw-100 align-items-center justify-content-center pb-5"
        style={{ backgroundColor: 'white', minHeight: '80vh' }}
      >
        <div
          className={
            'd-flex flex-column align-self-center align-items-center pb-5 ' + styles()['width']
          }
        >
          <p className="fw-bolder text-black" style={{ fontSize: styles()['titleFontSize'] }}>
            Course Rater
          </p>
          {/* autocomplete and search button */}
          <AutoComplete
            options={options}
            selected={selected}
            handleChange={(value) => handleChange(value)}
            placeholder={'Search classes (e.g. "' + item + '")'}
          />
          <div className="mt-4">
            <Button type="submit" text="Submit" onClick={handleSubmit} />
          </div>
        </div>
      </div>
      <div className={'d-flex justify-content-center' + (isMobile ? ' px-3 pb-3' : '')}>
        <StatusBar
          title="Schedule Rating Coming Soon"
          body='The "Rate My Schedule" feature is currently under construction! For now, enjoy reviewing and rating all Purdue courses.'
        />
      </div>
    </div>
  );
};
