import { useMutation } from 'react-query';
import { queryClient } from 'lib/react-query';
import PropTypes from 'prop-types';
import { axios } from 'lib/axios';

// creates a report
export const createReport = async ({ reviewId, body }) => {
  let reportObject = { reviewId: reviewId, messages: body };
  let report = await axios.get('reports/' + reviewId);
  if (report === null) {
    // If it doesn't exist, post it
    axios.post('reports', reportObject).then((res) => {
      return res;
    });
  } else {
    // If it does exist, patch it
    axios.patch(`reports/` + reviewId, reportObject).then((res) => {
      return res;
    });
  }
};

// uses react-query to handle creating a report
export const useCreateReport = ({ config, reviewId }) => {
  // noinspection JSIgnoredPromiseFromCall
  return useMutation({
    onMutate: async (newReport) => {
      await queryClient.cancelQueries(['reports', reviewId]);
      queryClient.setQueryData(['reports', reviewId], [newReport.data]);
      return { newReport };
    },
    onError: (_, __, context) => {
      if (context?.previousReports) {
        queryClient.setQueryData(['reports', reviewId], context.previousReports);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['reports', reviewId]);
    },
    ...config,
    mutationFn: createReport,
  });
};

useCreateReport.propTypes = {
  config: PropTypes.any,
  reviewId: PropTypes.string.isRequired,
};
