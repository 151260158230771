import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Voter } from 'features/reviews/components/Voter';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { NumberBox } from 'components/Elements';
import { CreateReport } from 'features/reports';
import { DeleteReview } from 'features/reviews/components/DeleteReview';
import { useUpdateVotes } from 'features/reviews/api/updateVotes';
import { protectedResources } from 'config/authConfig';
import { patchUserVotes } from 'features/auth/api/patchUserVotes';
import { getUser } from 'features/auth/api/getUser';
import { getReview } from 'features/reviews/api/getReview';

// box that holds an individual review
export const ReviewBox = ({ id, data }) => {
  const [upVoted, setUpVoted] = useState(false);
  const [downVoted, setDownVoted] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const updateVotesMutation = useUpdateVotes();
  const request = { scopes: protectedResources.apiCourseRater.scopes.write, account: accounts[0] };

  // handles when a user presses upvote or downvote on a review
  const handleVote = async (vote) => {
    instance.acquireTokenSilent(request).then(async (res) => {
      let accessToken = res.accessToken;
      await patchUserVotes({ accessToken, uid: res.account.localAccountId, reviewId: id, vote });
      await getReview({ reviewId: id }).then(async (rev) => {
        let votes = rev?.votes;
        const mutateObj = { accessToken, reviewId: id, uniqueId: res.account.localAccountId };
        if (vote === 'up') {
          if (!downVoted) {
            await updateVotesMutation.mutateAsync({ ...mutateObj, value: votes + 1 });
          } else {
            await updateVotesMutation.mutateAsync({ ...mutateObj, value: votes + 2 });
          }
          setUpVoted(!upVoted);
          setDownVoted(false);
        } else if (vote === 'down') {
          if (!upVoted) {
            await updateVotesMutation.mutateAsync({ ...mutateObj, value: votes - 1 });
          } else {
            await updateVotesMutation.mutateAsync({ ...mutateObj, value: votes - 2 });
          }
          setUpVoted(false);
          setDownVoted(!downVoted);
        } else {
          if (upVoted) {
            await updateVotesMutation.mutateAsync({ ...mutateObj, value: votes - 1 });
          } else {
            await updateVotesMutation.mutateAsync({ ...mutateObj, value: votes + 1 });
          }
          setUpVoted(false);
          setDownVoted(false);
        }
      });
    });
  };

  // on page load, gets the user object, then checks if the user is the author of this instance of a review box
  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        const uniqueId = accounts[0].localAccountId;
        await getUser({ uid: uniqueId }).then((user) => {
          let { upvotedReviews, downvotedReviews, _id } = user;
          if (upvotedReviews.includes(id)) {
            setUpVoted(true);
          } else if (downvotedReviews.includes(id)) {
            setDownVoted(true);
          }
          if (_id === data.userId) {
            setShowDeleteButton(true);
          }
        });
      }
    })();
  }, [accounts, id, isAuthenticated, data.userId]);

  // the text content
  const content = () => {
    return (
      <div className="d-flex g-3 flex-wrap">
        <p className="me-3">
          For credit: <span className="fw-bold">{convertBool(data.forCredit)}</span>
        </p>
        <p className="me-3">
          Delivery Method: <span className="fw-bold">{data.deliveryMethod}</span>
        </p>
        <p className="me-3">
          Hours per week: <span className="fw-bold">{data.hoursPerWeek}</span>
        </p>
        <p className="me-3">
          Attendance: <span className="fw-bold">{convertBool(data.attendanceRequired)}</span>
        </p>
        <p className="me-3">
          Would take again: <span className="fw-bold">{convertBool(data.isRecommended)}</span>
        </p>
        <p className="pe-3">
          Grade: <span className="fw-bold">{data.letterGrade}</span>
        </p>
        <p className="me-3">
          Textbook: <span className="fw-bold">{convertBool(data.hasTextbooks)}</span>
        </p>
        <p className="me-3">
          Final: <span className="fw-bold">{convertBool(data.hasFinal)}</span>
        </p>
        <p className="me-3">
          Midterms: <span className="fw-bold">{data.midterms}</span>
        </p>
      </div>
    );
  };

  // the header that shows professor and date
  const header = () => {
    return (
      <div className="d-flex justify-content-between">
        <p>
          Professor: <span className="fw-bold">{data.professor}</span>
        </p>
        <p className="fw-bold">{convertDate(data.date)}</p>
      </div>
    );
  };

  // mobile version
  if (isMobile) {
    return (
      <div
        className="d-flex flex-column align-self-center py-3 px-3 mt-3"
        style={{ border: '3px solid black', boxShadow: '5px 5px #000000', borderRadius: '10px' }}
      >
        {header()}
        <div className="d-flex align-items-center mb-2">
          <div className="d-flex flex-column align-items-center me-3">
            {/* number boxes that displays rating and difficulty */}
            <NumberBox
              isLoading={false}
              number={data.rating}
              desc="Rating"
              titlePosition="top"
              review={true}
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            <NumberBox
              isLoading={false}
              number={data.difficulty}
              desc="Difficulty"
              titlePosition="top"
              review={true}
            />
          </div>
        </div>
        {content()}
        <p>{data.review}</p>
        <div className="d-flex w-100 justify-content-between align-items-end">
          <div>
            {!showDeleteButton && <CreateReport reviewId={id} />}
            {showDeleteButton && <DeleteReview reviewId={id} courseName={data.className} />}
          </div>
          <div className="d-flex flex-row align-self-end">
            <Voter handleVote={(vote) => handleVote(vote)} up={upVoted} down={downVoted} />
          </div>
        </div>
      </div>
    );
  }

  // desktop/tablet version
  return (
    <div
      className={
        'd-flex h-25 align-self-center py-3 pe-3 mt-3 ' + (isTabletOrMobile ? 'w-100' : 'w-75')
      }
      style={{ border: '3px solid black', boxShadow: '5px 5px #000000', borderRadius: '10px' }}
    >
      <div
        className={'d-flex flex-column justify-content-center align-items-center row-gap-3 mx-5'}
      >
        <NumberBox
          isLoading={false}
          number={data.rating}
          desc="Rating"
          titlePosition="top"
          review={true}
          mb={true}
        />
        <NumberBox
          isLoading={false}
          number={data.difficulty}
          desc="Difficulty"
          titlePosition="top"
          review={true}
        />
      </div>

      <div className="d-flex flex-column">
        {header()}
        {content()}
        <p>{data.review}</p>
        <div className="d-flex h-100 justify-content-between align-items-end">
          <div className="d-flex flex-row align-self-center">
            <Voter handleVote={(vote) => handleVote(vote)} up={upVoted} down={downVoted} />
          </div>
          <div>
            {!showDeleteButton && <CreateReport reviewId={id} />}
            {showDeleteButton && <DeleteReview reviewId={id} courseName={data.className} />}
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewBox.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    professor: PropTypes.string,
    rating: PropTypes.number,
    difficulty: PropTypes.number,
    deliveryMethod: PropTypes.oneOf(['Online', 'In person', 'Hybrid']),
    hoursPerWeek: PropTypes.number,
    hasLabs: PropTypes.number,
    midterms: PropTypes.number,
    hasFinal: PropTypes.bool,
    isRecommended: PropTypes.bool,
    forCredit: PropTypes.bool,
    hasTextbooks: PropTypes.bool,
    attendanceRequired: PropTypes.bool,
    letterGrade: PropTypes.string,
    review: PropTypes.string,
    votes: PropTypes.number,
    date: PropTypes.string,
    userId: PropTypes.string,
    className: PropTypes.string,
  }).isRequired,
};

// converts a boolean to a string "Yes" or "No"
const convertBool = (bool) => {
  if (bool) {
    return 'Yes';
  } else {
    return 'No';
  }
};

// converts a date item to a string of the format (m)m-dd-yyyy
const convertDate = (date) => {
  let newDate = new Date(date);
  return newDate.toLocaleDateString().replace(/\//gi, '-');
};
