import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import PropTypes from 'prop-types';


// wrapper that wraps different form fields
export const FieldWrapper = ({ label, error, children, marginTop = true }) => {
  return (
    <div className={marginTop ? 'mt-3' : ''}>
      {label ?
        <label>
          {label}
        </label> :
        <></>}
      <div className='mt-1'>{children}</div>
      {error?.message && (
        <div role='alert' aria-label={error.message} className='text-danger fw-bold'>
          {error.message}
        </div>
      )}
    </div>
  );
};

FieldWrapper.propTypes = {
  label: PropTypes.string,
  error: PropTypes.object,
  children: PropTypes.element.isRequired,
  marginTop: PropTypes.bool,
};

