import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';

// button that can take on 4 different colors: pink (default), blue, yellow, and white
export const Logo = ({ size = 'normal' }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  if (size === 'small') {
    return (
      <div
        className="px-2"
        style={{
          backgroundColor: '#e35f8d',
          borderRadius: '8px',
          border: '2px solid black',
          boxShadow: isTabletOrMobile
            ? '6px 6px #e3b55f, 6px 6px 0px 2px black, -6px -6px #5fcfe3, -6px -6px 0px 2px black'
            : '4px 4px #e3b55f, 4px 4px 0px 2px black, -4px -4px #5fcfe3, -4px -4px 0px 2px black',
        }}
      >
        <h1
          style={{
            fontSize: isTabletOrMobile ? '2.3em' : '1em',
          }}
        >
          course-rater
        </h1>
      </div>
    );
  }
  return (
    <div
      className="mt-5 px-3"
      style={{
        backgroundColor: '#e35f8d',
        borderRadius: '8px',
        border: '2px solid black',
        boxShadow: isTabletOrMobile
          ? '6px 6px #e3b55f, 6px 6px 0px 2px black, -6px -6px #5fcfe3, -6px -6px 0px 2px black'
          : '8px 8px #e3b55f, 8px 8px 0px 2px black, -8px -8px #5fcfe3, -8px -8px 0px 2px black',
      }}
    >
      <h1
        style={{
          fontSize: isTabletOrMobile ? '2.3em' : '3.2em',
          fontWeight: 'bold',
        }}
      >
        course-rater
      </h1>
    </div>
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(['normal', 'small']),
};

