import { useRoutes } from 'react-router-dom';

import { publicRoutes } from './public';
import { Home } from 'features/misc';
import { usePageTracking } from 'utils/tracking';

export const AppRoutes = () => {
  usePageTracking();
  
  const commonRoutes = [{ path: '/', element: <Home /> }];

  const routes = [...publicRoutes];

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
