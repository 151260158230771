import React from 'react';
import 'App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useParams } from 'react-router-dom';
import { isClassValid } from 'data/CourseParser';
import { Class } from 'features/courses';
import { NotFound } from 'features/misc';

// checks if the class exists, otherwise send user to 404 not found page
export default function ClassErrorChecker() {
  let { courseName } = useParams();
  courseName = courseName.replace('-', ' ').toUpperCase();
  const subject = courseName.split(' ')[0];
  const courseNum = courseName.split(' ')[1];
  if (isClassValid(subject, courseNum)) {
    return <Class />;
  }
  return <NotFound />;
}