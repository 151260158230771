import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { FieldWrapper } from 'components/Form/FieldWrapper';
import 'components/Form/styles/SelectField.scss';
import PropTypes from 'prop-types';

// select field used in forms, uses a dropdown menu that a user must pick from
export const SelectField = ({ label, options, error, defaultValue, registration, placeholder }) => {
  return (
    <FieldWrapper label={label} error={error}>
      <select
        className="form-select"
        id="select-field"
        aria-label="Select Field"
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...registration}
      >
        {options.map((label) => (
          <option key={label?.toString()} value={label} style={{ color: 'black' }}>
            {label}
          </option>
        ))}
      </select>
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  options: PropTypes.array.isRequired,
  registration: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
};