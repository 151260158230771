import { lazyImport } from 'utils/lazyImport';
import { MainLayout } from 'components/Layout';
import { Suspense } from 'react';
import { Spinner } from 'components/Elements';
import { Outlet } from 'react-router-dom';
import { Home, NotFound } from 'features/misc';
import ClassErrorChecker from 'features/courses/components/ClassErrorChecker';

// lazy import other pages
const { ScheduleRater } = lazyImport(() => import('features/schedule'), 'ScheduleRater');
const { Scores } = lazyImport(() => import('features/scores'), 'Scores');
const { About } = lazyImport(() => import('features/misc'), 'About');
const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <Spinner />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

// public routes, routes accessible without authentication
export const publicRoutes = [
  {
    path: '',
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/schedule', element: <ScheduleRater /> },
      { path: '/b20', element: <Scores /> },
      { path: '/about', element: <About /> },
      { path: '/:courseName', element: <ClassErrorChecker /> },
      { path: '/*', element: <NotFound /> },
    ],
  },
];
