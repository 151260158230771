import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

// gets a review by its mongo db review id
export async function getReview({ reviewId }) {
  return await axios.get('reviews/' + reviewId);
}

// query function for getting a review by id
export const useReview = ({ reviewId, config }) => {
  return useQuery({
    queryKey: ['review', reviewId],
    queryFn: () => getReview({ reviewId }),
    ...config,
  });
};
