import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../styles/ClassRatingForm.scss';
import { getClassProfessors } from 'data/CourseParser';
import { Controller } from 'react-hook-form';
import { Button } from 'components/Elements';
import * as z from 'zod';
import {
  DatalistInputField,
  Form,
  InputField,
  RadioField,
  RatingSlider,
  SelectField,
  TextAreaField,
} from 'components/Form';
import PropTypes from 'prop-types';

// schema for class rating form
const schema = z.object({
  professor: z.string().min(2, 'Required'),
  deliveryMethod: z.string().min(1, 'Required'),
  rating: z
    .number({
      required_error: 'Please provide a rating.',
    })
    .min(1, 'Please provide a rating.'),
  difficulty: z
    .number({
      required_error: 'Please provide a difficulty.',
    })
    .min(1, 'Please provide a difficulty.'),
  hoursPerWeek: z.string().min(0, 'Hours per week cannot have a negative value.'),
  hasFinal: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(4, 'Required'),
  midterms: z
    .string()
    .min(0, 'Midterms cannot have a negative value.')
    .max(1, 'Please enter a valid number.'),
  hasLabs: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(4, 'Required'),
  isRecommended: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(4, 'Required'),
  forCredit: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(4, 'Required'),
  hasTextbooks: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(4, 'Required'),
  attendanceRequired: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(4, 'Required'),
  letterGrade: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(1, 'Required'),
  review: z
    .string({
      invalid_type_error: 'Please provide an answer.',
    })
    .min(1, 'Required')
    .max(1000, 'Review is too long.'),
});

// the rating form, interface for users to rate a class
export const RatingForm = ({ courseName, onSubmit }) => {
  courseName = courseName.replace('-', ' ').toUpperCase();
  // list of valid grades, used for dropdown
  let grades = [
    'A+',
    'A',
    'A-',
    'B+',
    'B',
    'B-',
    'C+',
    'C',
    'C-',
    'D+',
    'D',
    'D-',
    'F',
    'Audit/No Grade',
    'Drop/Withdrawal',
    'Incomplete',
    'Not sure yet',
    'Rather not say',
  ];
  let subject = courseName.split(' ')[0];
  let courseNum = courseName.split(' ')[1];
  let profs = getClassProfessors(subject, courseNum);

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-self-center">
          {/* the form */}
          <Form onSubmit={(data) => onSubmit(data)} schema={schema}>
            {({ register, formState, control }) => (
              <>
                <DatalistInputField
                  label="Select Professor"
                  registration={register('professor', { required: true })}
                  data={profs}
                  error={formState.errors['professor']}
                />
                <SelectField
                  label="Select Delivery Method"
                  registration={register('deliveryMethod', { required: true })}
                  options={['In person', 'Online', 'Hybrid']}
                  error={formState.errors['deliveryMethod']}
                />
                <div className="mt-3 align-self-center">
                  <label className="mb-3">What what you rate this class?</label>
                  <Controller
                    name="rating"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="d-flex justify-content-center">
                        <RatingSlider
                          rating={value}
                          onSliderChange={onChange}
                          ratingsArray={['Awful', 'OK', 'Good', 'Great', 'Awesome']}
                          error={formState.errors['rating']}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className="mt-3">
                  <label className="mb-3">How difficult was this class?</label>
                  <Controller
                    name="difficulty"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="d-flex justify-content-center">
                        <RatingSlider
                          rating={value}
                          onSliderChange={onChange}
                          ratingsArray={[
                            'Very Easy',
                            'Easy',
                            'Average',
                            'Difficult',
                            'Very Difficult',
                          ]}
                          error={formState.errors['difficulty']}
                        />
                      </div>
                    )}
                  />
                </div>
                <InputField
                  label="How many hours per week did you spend on this class?"
                  type="number"
                  registration={register('hoursPerWeek', { required: true })}
                  error={formState.errors['hoursPerWeek']}
                />

                <RadioField
                  label="Is there a final?"
                  registration={register('hasFinal', { required: true })}
                  error={formState.errors['hasFinal']}
                />
                <InputField
                  label="How many midterms?"
                  type="number"
                  registration={register('midterms', { required: true })}
                  error={formState.errors['midterms']}
                />
                <RadioField
                  label="Is there a lab?"
                  registration={register('hasLabs', { required: true })}
                  error={formState.errors['hasLabs']}
                />
                <RadioField
                  label="Would you recommend this class?"
                  registration={register('isRecommended', { required: true })}
                  error={formState.errors['isRecommended']}
                />
                <RadioField
                  label="Was the class taken for credit?"
                  registration={register('forCredit', { required: true })}
                  error={formState.errors['forCredit']}
                />
                <RadioField
                  label="Did this class use textbooks?"
                  registration={register('hasTextbooks', { required: true })}
                  error={formState.errors['hasTextbooks']}
                />
                <RadioField
                  label="Was attendance mandatory?"
                  registration={register('attendanceRequired', { required: true })}
                  error={formState.errors['attendanceRequired']}
                />
                <SelectField
                  label="Select grade"
                  registration={register('letterGrade', { required: true })}
                  error={formState.errors['letterGrade']}
                  options={grades}
                />
                <TextAreaField
                  label="Write a review"
                  registration={register('review', { required: true })}
                  error={formState.errors['review']}
                />
                <div className="mt-3">
                  <strong>Before</strong> you submit:
                  <ul>
                    <li>Proofread your review.</li>
                    <li>
                      Please make sure that your answers are honest and to the best of your ability.
                    </li>
                    <li>
                      Know that your post could be removed if it is derogatory or uses profanity.
                    </li>
                    <li>All posts are anonymous.</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <Button type="submit" text="Submit" />
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

RatingForm.propTypes = {
  courseName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
